import firebase from 'firebase/compat/app';
import * as types from './actionTypes.js';
import { auth } from '../databaseRepository/database.js';
import { config } from '../config/index.js';

export function resetPassword(email) {
    return async (dispatch) => {
        try {
            await auth.sendPasswordResetEmail(email);
            dispatch({ type: types.AUTH_PASSWORD_RESET });
        } catch (error) {
            const errorMessage = error.message;
            alert(errorMessage);
        }
    };
}

export function loginWithEmailAndPassword(email, password) {
    return async (dispatch) => {
        let credentials;
        try {
            credentials = await auth.signInWithEmailAndPassword(email, password);
        } catch (error) {
            if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') return new Error('User not found');
            if (error.code === 'auth/too-many-requests') return new Error('Too many requests');

            alert(JSON.stringify(error));
        }

        if (!credentials) return;
        dispatch({ type: types.AUTH_DATA_REQUESTED });
    };
}

export function loginWithMagicLink(token) {
    return async (dispatch) => {
        dispatch({ type: types.AUTH_DATA_REQUESTED });

        try {
            const authTokenResponse = await fetch(`${config.functionsUrl}/magicLinkSignOn-magicLinkSignOn`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });
            const body = await authTokenResponse.json();
            if(!authTokenResponse.ok) {
                throw new Error(JSON.stringify(body));
            }
            await auth.signInWithCustomToken(body.authToken);
        } catch (error) {
            dispatch({ type: types.AUTH_LOGIN_FAILED });
        }
        return Promise.resolve();
    };
}

export function authDataRequested() {
    return dispatch => dispatch({ type: types.AUTH_DATA_REQUESTED });
}

export function logout() {
    return async (dispatch) => {
        try {
            auth.signOut();
            dispatch({ type: types.AUTH_SIGNOUT });
        } catch (error) {
            const errorMessage = error.message;
            alert(errorMessage);
        }
        return Promise.resolve();
    };
}

export function changePassword(oldPassword, newPassword) {
    return async () => {
        const user = auth.currentUser;
        const credentials = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);
        try {
            await user.reauthenticateWithCredential(credentials);
            await user.updatePassword(newPassword);
        } catch (error) {
            return error.message;
        }
    };
}
