import React from 'react';
import PropTypes from 'prop-types';
import '@algostrata/risk-questionnaire';
import DefaultPortraitPageTemplate from './Pages/PageTemplate/DefaultPortraitPageTemplate.js';

export default function MifidReport(props) {
    const { state } = props;
    const { mifidData, customer } = state;
    console.log(state);
    return (
        <div>
            <DefaultPortraitPageTemplate { ...props }>
                <p>Kunde: { customer.name }</p>
                <hr style={{ height: '2px', borderWidth: 0, color: 'gray', backgroundColor: 'gray' }} />
                <risk-questionnaire
                    id='myRiskQuestionnaire'
                    initial-selected-questions={ JSON.stringify(mifidData?.selectedQuestions) }
                    initial-comment={ mifidData?.comment }
                    is-finished={ mifidData?.finished }
                    disabled={ true }
                    card-layout={ false }
                    print-mode={ true }
                ></risk-questionnaire>
            </DefaultPortraitPageTemplate>
        </div>
    );
}

MifidReport.propTypes = {
    state: PropTypes.object.isRequired,
};
