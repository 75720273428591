import initialState from './initialState.js';
import * as types from '../actions/actionTypes.js';

export default function providerDataReducer(state = initialState.providerData, action) {
    switch (action.type) {
        case types.PROVIDERS_LOADED:
            return { ...state, ...action.providerData };
        default:
            return state;
    }
}
