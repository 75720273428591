import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

import { config } from '../config/index.js';

firebase.initializeApp(config);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();

if (config.env === 'local') {
    db.settings({
        host: config.firestoreHost,
        ssl: false,
    });
    storage.useEmulator('localhost', 5104);
    auth.useEmulator('http://localhost:5101', { disableWarnings: true });
}
