import initialState from './initialState.js';
import * as types from '../actions/actionTypes.js';


export default function configReducer(state = initialState.config, action) {
    switch (action.type) {
        case types.CONFIG_CHANGED: {
            const { config } = action.payload;
            return { ...state, ...config };
        }
        default:
            return state;
    }
}
