import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, IconButton, Dialog, useTheme } from '@mui/material';
import { Menu as MenuIcon , Close as CloseIcon } from '@mui/icons-material';

import LoadingPage from '../LoadingPage.js';
import { isNullOrWhitespace } from '../../utils/helperFunctions.js';
import {
    addOrUpdateAnswer as addOrUpdateAnswerAction,
    getOnboardingProcess,
    updateOnboardingProcessField as updateOnboardingProcessFieldAction,
    addFileToAnswer as addFileToAnswerAction,
} from '../../actions/onboardingActions.js';
import { fetchData as fetchDataAction, fetchPdf as fetchPdfAction } from '../../actions/uniifyActions.js';
import { loginWithMagicLink } from '../../actions/authActions.js';
import { getMagicLinkToken } from '../../databaseRepository/magicLinkDataManager.js';
import { getCustomerBaseData } from '../../databaseRepository/customerDataManager.js';
import Overview from './Overview.js';
import Questions from './Questions.js';
import { pension as pensionGroupType } from '../../reducers/onboardingQuestionGroupTypes.js';
import { kreditDataPension as kreditDataPensionType } from '../../reducers/onboardingQuestionTypes.js';
import { uploadPensionFileOnboardingLink } from '../../actions/customerActions.js';

export default function OnboardingLink() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const theme = useTheme();

    const queryParams = new URLSearchParams(window.location.search);
    const kreditdataIdFromFlowCompletionRedirect = queryParams.get('kreditdata_id');
    const kreditDataPerson = queryParams.get('kreditdata_person');
    const onboardingProcessesData = useSelector(state => state.onboardingProcessesData);
    const [partnerId, setPartnerId] = useState(null);
    const [customer, setCustomer] = useState(null);
    const loggedIn = useSelector(state => state.auth.loggedIn);
    const globalLoading = useSelector(state => state.auth.globalLoading);
    const loginFailed = useSelector(state => state?.auth?.loginFailed ?? false);
    const [processId, setProcessId] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [kreditDataLoading, setkreditDataLoading] = useState(false);
    const [showOverview, setShowOverview] = useState(true);
    const [saveAndClose, setSaveAndClose] = useState(false);
    const [closed, setClosed] = useState(false);
    const [startActiveQuestionGroup, setStartActiveQuestionGroup] = useState({ groupId: null, activeResponder: null });

    const urlParts = location.pathname;
    const process = onboardingProcessesData?.processes?.find(x => x.processId === processId);
    const questionnaire = process?.questionnaire;
    const answers = process?.answers;

    const token = useMemo(() => {
        const urlPartsSplit = urlParts.split('/');
        return urlPartsSplit[urlPartsSplit.length - 1];
    }, [urlParts]);

    const [ open, setOpen ] = useState(false);;

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        async function getData(){
            if(!isNullOrWhitespace(token) && !globalLoading) {
                if (!loggedIn) await dispatch(loginWithMagicLink(token));
                const tokenData = await getMagicLinkToken(token);
                setCustomer(await getCustomerBaseData(tokenData.partnerId, tokenData.customerId));
                setProcessId(tokenData.processId);
                await dispatch(getOnboardingProcess(tokenData.partnerId, tokenData.customerId, tokenData.processId));
                setPartnerId(tokenData.partnerId);
                setLoaded(true);
            }
        }
        getData();
    }, [dispatch, globalLoading, loggedIn, token]);

    useEffect(() => {
        async function getKreditData(){
            console.log('Start Dispatch get kreditdata');
            if (customer?.customerId && kreditdataIdFromFlowCompletionRedirect && kreditDataPerson && questionnaire && processId && !kreditDataLoading) {
                setkreditDataLoading(true);
                const index = questionnaire?.questionGroups.findIndex(x => x.type === pensionGroupType);
                if(index === -1) {
                    history.replace({
                        search: '',
                    });
                    setkreditDataLoading(false);
                    throw new Error('Pension question not a found!');
                }
                const pensionQuestionGroup = questionnaire?.questionGroups[index];
                const kreditdataQuestion = pensionQuestionGroup.questions.find(x => x.type === kreditDataPensionType);

                let localResponder;
                if(kreditDataPerson === 'customer') localResponder = answers?.customerAnswerGroups;
                else if(kreditDataPerson === 'spouse') localResponder = answers?.spouseAnswerGroups;

                const pensionAnswerGroupIndex = localResponder?.findIndex(x => x.id === pensionQuestionGroup.id) ?? -1;
                const pensionAnswerGroup = pensionAnswerGroupIndex !== -1 ? localResponder[pensionAnswerGroupIndex] : null;
                const kredidataAnswer = pensionAnswerGroup?.answers.find(x => x.id === kreditdataQuestion.id);

                if(kredidataAnswer?.answerData?.kreditdata !== undefined && kredidataAnswer?.answerData?.kreditdata !== null) {
                    setkreditDataLoading(false);
                    history.replace({
                        search: '',
                    });
                    return;
                };

                console.log('Dispatch get kreditdata');

                const orgKreditdata = await dispatch(fetchDataAction(kreditdataIdFromFlowCompletionRedirect, customer.customerId));
                const slimKredidata = { profil: orgKreditdata.profil, jsonSourceResponse:  JSON.stringify(orgKreditdata.sourceResponse), source: orgKreditdata.source, version: orgKreditdata.version };
                await dispatch(addOrUpdateAnswerAction(processId, pensionQuestionGroup.id, kreditdataQuestion.id, kreditDataPerson, { kreditdata: slimKredidata }));
                const base64String = await dispatch(fetchPdfAction(kreditdataIdFromFlowCompletionRedirect, customer.customerId));
                const base64Response = await fetch(`data:application/pdf;base64,${base64String}`);
                const fileBlob = await base64Response.blob();
                const { fileId, fileName } = await dispatch(uploadPensionFileOnboardingLink(customer?.customerId, fileBlob, `pensionsInfo_${kreditDataPerson}.pdf`, kreditDataPerson === 'spouse'));
                await dispatch(addFileToAnswerAction(processId, kreditDataPerson, pensionQuestionGroup.id, kreditdataQuestion.id, fileId, fileName));

                setStartActiveQuestionGroup({ groupId: pensionQuestionGroup.id, activeResponder: kreditDataPerson });
                setShowOverview(false);
                setkreditDataLoading(false);

                history.replace({
                    search: '',
                });
            }
        }
        getKreditData();
    }, [customer, dispatch, kreditdataIdFromFlowCompletionRedirect, kreditDataPerson, questionnaire, processId, answers, kreditDataLoading, history]);

    if(loginFailed) {
        // Simulate an HTTP redirect:
        alert('Linket er ugyldigt!');
        window.location.replace('http://monax.dk');
    }

    let imageObj = null;
    switch (partnerId) {
        case 'uvildigraad':
            imageObj = <img style={{ display: 'block', marginTop: 0, marginLeft: 'auto', marginRight: 'auto', width: '450px', height: 'auto', padding: '25px 0', cursor: 'pointer' }} src="/assets/img/uvildigraad/logo.png" onClick={ () => { setShowOverview(true); } } />;
            break;
        case 'f10':
            imageObj = <img style={{ float: 'left', marginTop: 10, marginLeft: 40, width: 'auto', height: '60px', paddingTop: '25px 0', cursor: 'pointer' }} src="/assets/img/f10/logo-2.png" onClick={ () => { setShowOverview(true); } } />;
            break;
        case 'ml-advice':
            imageObj = <img style={{ float: 'left', marginTop: 29, marginLeft: 20, cursor: 'pointer' }} src="/assets/img/default/logo-off.svg" onClick={ () => { setShowOverview(true); } } />;
            break;
        case 'dannebroginvest':
            imageObj = <img style={{ float: 'left', marginTop: 0, marginLeft: 10, width: 'auto', height: '80px', paddingTop: '25px 0', cursor: 'pointer' }} src="/assets/img/dannebroginvest/dannebroginvest-logo.png" onClick={ () => { setShowOverview(true); } } />;
            break;
        case 'arvadfinanshus':
            imageObj = <img style={{ float: 'left', marginTop: 0, marginLeft: 14, width: 'auto', height: '80px', paddingTop: '25px 0', cursor: 'pointer' }} src="/assets/img/arvadfinanshus/arvadfinanshus-logo-landscape.png" onClick={ () => { setShowOverview(true); } } />;
            break;
        default:
            break;
    }

    const updateOnboardingProcessField = useCallback((fieldName, fieldValue) =>
        dispatch(updateOnboardingProcessFieldAction(processId, fieldName, fieldValue)), [dispatch, processId]);

    const closePage = () =>{
        setClosed(true);
        updateOnboardingProcessField('started', true);
    };

    // if(!loaded || !loggedIn || closing) return <LoadingPage />;
    if(!loaded || !loggedIn || kreditDataLoading) return <LoadingPage />;

    function goToLink(groupId, activeResponder) {
        setStartActiveQuestionGroup({ groupId, activeResponder });
        setShowOverview(false);
    }

    return(
        <div style={{ backgroundColor: theme.palette.background.default }}>
            <div style={{ backgroundColor: theme.palette.secondary.main, height: 80 }}>
                <div style={{ maxWidth: 1200, height: 80, margin: 'auto' }}>
                    { imageObj }
                    <div style={{ float: 'right', marginTop: 24, cursor: 'pointer', color: theme.palette.primary.contrastText }} >
                        { !showOverview && <Button style={{ marginLeft: 10 }} onClick={ () => { setShowOverview(true); } } size="large">
                            Til forsiden
                        </Button> }
                        <Button startIcon={<MenuIcon/>} style={{ marginLeft: 10 }} onClick={ () => setOpen(true) } size="large">
                            Menu
                        </Button>
                    </div>
                    <Dialog
                        PaperProps={{
                            sx: {
                                position: 'fixed',
                                top: 0,
                                right: 0,
                                m: 0,
                                width: '768',
                                height: '100%',
                                maxHeight: '100%',
                            },
                            square: true,
                        }}
                        onClose={ handleClose } open={ open } aria-labelledby="form-dialog-title"
                    >
                        <div style={{ backgroundColor: theme.palette.secondary.main, height: 80 }}>
                            <IconButton
                                style={{ float: 'right', marginTop: 13, color: theme.palette.secondary.contrastText }}
                                aria-label='open drawer'
                                onClick={ () => setOpen(false) }
                                size="large"
                            >
                                <CloseIcon fontSize="large"/><span>Luk</span>
                            </IconButton>
                        </div>
                        <div style={{ padding: '24px 16px' }}>
                            <span style={{ fontSize: 16, weight: 500, fontWeight: 'bold' }}>{ customer.name }</span>
                            <br />
                            <span style={{ fontSize: 16, weight: 500 }}>{ customer.email }</span>
                            <br />
                            <div style={{ marginTop: 20, paddingBottom: 25, display: 'flex',  justifyContent: 'center', borderBottom: '1px solid', borderColor: theme.palette.divider }}>
                                <Button style={{ marginRight: 20 }} onClick={ () => { setOpen(false); setSaveAndClose(true); } } >Gem og luk</Button>
                                <Button style={{  }} onClick={ () => { setOpen(false); setShowOverview(!showOverview); } }>{ showOverview ? 'Start' : 'Tilbage til forsiden' }</Button>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
            <div style={{ maxWidth: 1200, margin: 'auto' }}>
                { !saveAndClose ?
                    <>
                        { showOverview && <Overview questionnaire={ questionnaire } customer={ customer } answers={ answers } goToLink={ goToLink } /> }
                        { !showOverview && <Questions
                            processId={ processId }
                            partnerId={ partnerId }
                            customer={ customer }
                            startActiveQuestionGroup={ startActiveQuestionGroup }
                        /> }
                        { showOverview &&
                            <div style={{ display: 'flex',  justifyContent: 'center' }}>
                                <Button style={{ float: 'center', marginRight: 150, marginTop: 25, marginBottom: 25 }} onClick={ () => { setShowOverview(false); setStartActiveQuestionGroup({ groupId: null, activeResponder: null }); } }>Start</Button>
                            </div>
                        }
                    </>
                    : <div style={{ textAlign: 'center' }}>
                        <div style={{ display: 'inline-block', width: '50%', textAlign: 'center', border: '2px solid #ccc', borderRadius: '16px', padding: '12.5px 25px' }}>
                            { !closed ?
                                <>
                                    Din delbesvarelse er nu sendt. Er du sikker på du vil afslutte uden at have udfyldt alle felter?
                                    <br />
                                    Du kan stadig vende tilbage senere og udfylde resten af informationen senere.
                                    <br />
                                    <div style={{ marginTop: 10 }}>
                                        <Button onClick={ closePage }>Ja</Button> <Button onClick={ () => setSaveAndClose(false) }>Nej</Button>
                                    </div>
                                </> :
                                'Du kan nu lukke siden. Du kan vende tilbage senere og udfylde resten af informationerne.'
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
