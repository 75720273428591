import React from 'react';
import PropTypes from 'prop-types';
import { styled, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { renderKr, renderPercent } from '../../utils/renderingService.js';

const StyledTableCell = styled(TableCell)(()=> ({
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
}));

export default function CompareCostsTable({ data }) {
    const {
        currentCosts, targetCosts, oneTimeManagementCost,
        currentCostsInKr, targetCostsInKr,
        currentCostsInPercent, targetCostsInPercent, targetCostsFirstYearInPercent,
        targetCostsFirstYearInKr, savingInKr, savingFirstYearInKr,
        relativeSavingFirstYearInPercent, relativeSavingInPercent,
    } = data;

    const localRenderKr = kr => renderKr(kr);

    return (
        <Paper variant="outlined">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Nuværende portefølje</TableCell>
                        <TableCell>Foreslået portefølje</TableCell>
                        <TableCell>Forskel</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell><b>Synlige omkostninger</b></TableCell>
                        <StyledTableCell></StyledTableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Pleje-/forvaltningsomkostninger</TableCell>
                        <StyledTableCell>{ localRenderKr(currentCosts.Plejeomkostninger.value) }</StyledTableCell>
                        <StyledTableCell>{ localRenderKr(targetCosts.Plejeomkostninger.value) }</StyledTableCell>
                        <StyledTableCell>{ localRenderKr(currentCosts.Plejeomkostninger.value - targetCosts.Plejeomkostninger.value) }</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>Ikke synlige omkostninger</b></TableCell>
                        <StyledTableCell></StyledTableCell>
                        <TableCell style={{ borderRight: '0px', borderLeft: '0px' }}></TableCell>
                        <TableCell style={{ borderLeft: '0px' }}></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Spreadomkostninger på depot</TableCell>
                        <StyledTableCell>{ localRenderKr(currentCosts.SpreadDepot.value) }</StyledTableCell>
                        <StyledTableCell>{ localRenderKr(targetCosts.SpreadDepot.value) }</StyledTableCell>
                        <StyledTableCell>{ localRenderKr(currentCosts.SpreadDepot.value - targetCosts.SpreadDepot.value) }</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Omkostninger Investeringsforeninger</TableCell>
                        <StyledTableCell>{ localRenderKr(currentCosts.LobendeOmk.value) }</StyledTableCell>
                        <StyledTableCell>{ localRenderKr(targetCosts.LobendeOmk.value) }</StyledTableCell>
                        <StyledTableCell>{ localRenderKr(currentCosts.LobendeOmk.value - targetCosts.LobendeOmk.value) }</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Spreadomkostninger inde i foreninger</TableCell>
                        <StyledTableCell>{ localRenderKr(currentCosts.SpreadIForeninger.value) }</StyledTableCell>
                        <StyledTableCell>{ localRenderKr(targetCosts.SpreadIForeninger.value) }</StyledTableCell>
                        <StyledTableCell>{ localRenderKr(currentCosts.SpreadIForeninger.value - targetCosts.SpreadIForeninger.value) }</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>Total omkostninger</b></TableCell>
                        <StyledTableCell><b>{ localRenderKr(currentCostsInKr) }</b></StyledTableCell>
                        <StyledTableCell><b>{ localRenderKr(targetCostsInKr) }  { (() => {if (oneTimeManagementCost) return `(${localRenderKr(targetCostsFirstYearInKr)})*`;})() }</b></StyledTableCell>
                        <StyledTableCell><b>{ localRenderKr(savingInKr) } { (() => {if (oneTimeManagementCost) return `(${localRenderKr(savingFirstYearInKr)})*`;})() }</b></StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>Total</b></TableCell>
                        <StyledTableCell><b>{ renderPercent(currentCostsInPercent) }</b></StyledTableCell>
                        <StyledTableCell><b>{ renderPercent(targetCostsInPercent) } { (() => {if (oneTimeManagementCost) return `(${renderPercent(targetCostsFirstYearInPercent)})*`;})() }</b></StyledTableCell>
                        <StyledTableCell><b>{ renderPercent(currentCostsInPercent - targetCostsInPercent) } { (() => {if (oneTimeManagementCost) return `(${renderPercent(currentCostsInPercent - targetCostsFirstYearInPercent)})*`;})() }</b></StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Årlig besparelse i %</TableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell><b>{ renderPercent(relativeSavingInPercent) } { (() => {if (oneTimeManagementCost) return `(${renderPercent(relativeSavingFirstYearInPercent)})*`;})() }</b></StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Årlig besparelse i kroner</TableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell><b>{ localRenderKr(savingInKr) } { (() => {if (oneTimeManagementCost) return `(${localRenderKr(savingFirstYearInKr)})*`;})() }</b></StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    );
};

CompareCostsTable.propTypes = {
    data: PropTypes.object.isRequired,
};
