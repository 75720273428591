import { config } from '../config/index.js';
import * as types from './actionTypes.js';
import { getTealBusinessToken } from '../apiRepository/monaxFunctions.js';
import { createScrapeFromContent, setToken as setTealToken } from '../apiRepository/teal.js';

export function inviteUser({ redirectUrl, customerId } = {}) {
    return async (dispatch) => {
        if (!redirectUrl) redirectUrl = window.location.href; // eslint-disable-line no-param-reassign
        const res = await fetch(`${config.functionsUrl}/uniify-invite`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ redirectUrl, customerId }),
        });
        const data = await res.json();
        dispatch({
            type: types.UNIIFY_INVITE_SUCCESS,
            payload: data,
        });
        return Promise.resolve(data);
    };
}

export function fetchData(id, customerId) {
    return async (dispatch) => {
        try {
            const fetchRes = await fetch(`${config.functionsUrl}/uniify-fetch`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id, customerId }),
            });
            const { content } = await fetchRes.json();
            dispatch({
                type: types.UNIIFY_FETCH_SUCCESS,
            });
            return Promise.resolve(content);
        } catch (error) {
            console.error(error);
        }
        return Promise.resolve();
    };
}

export function fetchPdf(id, customerId) {
    return async (dispatch) => {
        try {
            const fetchRes = await fetch(`${config.functionsUrl}/uniify-fetchPdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id, customerId }),
            });
            const { base64String } = await fetchRes.json();
            dispatch({
                type: types.UNIIFY_FETCH_PDF_SUCCESS,
            });
            return Promise.resolve(base64String);
        } catch (error) {
            console.error(error);
        }
        return Promise.resolve();
    };
}

export function fetchPensionPartnerToken(slimKreditData) {
    return async (dispatch) => {
        try {
            const kreditData = { profil: slimKreditData.profil, sourceResponse: JSON.parse(slimKreditData.jsonSourceResponse), source: slimKreditData.source, version: slimKreditData.version };
            const tealBusinessToken = await getTealBusinessToken();
            setTealToken(tealBusinessToken);
            const scrape = await createScrapeFromContent({ content: kreditData });
            dispatch({
                type: types.UNIIFY_FETCH_SUCCESS,
            });
            return Promise.resolve(scrape);
        } catch (error) {
            console.error(error);
        }
        return Promise.resolve();
    };
}
