import React from 'react';
import PropTypes from 'prop-types';
import { getExcessTurnoverRatio, getExcessTurnoverYears, renderExcessTurnoverYears } from '../../utils/renderingService.js';
import InputComponent from './InputComponent.js';

export default function ExcessTurnoverRatioInputComponent({ value, name, disabled, onChange, variant, size, inputPropsStyle, textAlign, disableBorder, label, fullWidth, placeholder }) {
    return (
        <InputComponent
            name={ name }
            value={ value }
            onChange={ x => onChange(getExcessTurnoverRatio(Number(x).toFixed(2))) }
            disabled={ disabled }
            inputPropsStyle={ inputPropsStyle }
            renderFunc={ x => renderExcessTurnoverYears(x) }
            parserFunc={ x => getExcessTurnoverYears(x) }
            variant={ variant }
            size={ size }
            disableBorder={ disableBorder }
            textAlign={ textAlign }
            activeType='number'
            label={ label }
            fullWidth={ fullWidth }
            placeholder={ placeholder }
        />
    );
}

ExcessTurnoverRatioInputComponent.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    size: PropTypes.string,
    inputPropsStyle: PropTypes.object,
    textAlign: PropTypes.string,
    disableBorder: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    fullWidth: PropTypes.bool,
};
