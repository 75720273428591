import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, List, ListItem } from "@mui/material";
import { PieChart, Pie, Cell } from "recharts";
import { renderPercent } from "../../utils/renderingService.js";

export default function CountryExposurePieChart({
  portfolioCountryExposure = null,
  name,
  colors,
}) {
  const sum = portfolioCountryExposure.reduce(
    (partialSum, a) => partialSum + a.value,
    0
  );
  const checkSum = portfolioCountryExposure
    .filter((x) => x.countryName !== "Andre")
    .reduce((partialSum, a) => partialSum + a.value, 0);
  portfolioCountryExposure.push({ countryName: "Andre", value: 1 - sum });
  const portfolioCountryExposureWithSorted = portfolioCountryExposure?.sort(
    (a, b) => b.value - a.value
  );
  const oneActive100 = portfolioCountryExposureWithSorted?.some(
    (x) => x.value >= 1
  );

  if (!portfolioCountryExposure || checkSum === 0) {
    return (
      <>
        <Typography variant="h5" gutterBottom>
          {name}
        </Typography>
        <p>
          Data ikke tilgængelig, det kan skylde at porteføljen består
          udelukkende af obligationsfonde!
        </p>
      </>
    );
  }

  return (
    <>
      <Typography variant="h5" gutterBottom paragraph align="center">
        {name}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <PieChart width={250} height={150}>
            <Pie
              data={portfolioCountryExposureWithSorted}
              innerRadius={20}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={oneActive100 ? 0 : 1}
              dataKey="value"
              legendType="line"
              label={false}
              isAnimationActive={false}
            >
              {portfolioCountryExposureWithSorted.map((entry) => (
                <Cell
                  key={entry.countryName}
                  fill={`${
                    colors.find((x) => x.countryName === entry.countryName)
                      .color
                  }`}
                />
              ))}
            </Pie>
          </PieChart>
        </Grid>
        <Grid item>
          <List style={{ padding: 0 }}>
            {portfolioCountryExposureWithSorted.map((row, index) => {
              return (
                <ListItem key={`${name}_${index}`} style={{ padding: 0 }}>
                  <span style={{ fontSize: "150%" }}>
                    <span
                      style={{
                        color: `${
                          colors.find((x) => x.countryName === row.countryName)
                            .color
                        }`,
                      }}
                    >
                      &#9673;
                    </span>{" "}
                    {row.countryName}: {renderPercent(row.value)}
                  </span>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </>
  );
}

CountryExposurePieChart.propTypes = {
  portfolioCountryExposure: PropTypes.array,
  colors: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};
