import React from 'react';
import PropTypes from 'prop-types';
import { renderPercent } from '../../utils/renderingService.js';
import InputComponent from './InputComponent.js';
import { isNullOrNaN } from '../../utils/helperFunctions.js';

export default function PercentInputComponent({ value, name, disabled, onChange, variant, size, inputPropsStyle, disableBorder, textAlign, label, fullWidth, placeholder }) {
    return (
        <InputComponent
            name={ name }
            value={ value }
            disabled={ disabled }
            onChange={ x => {
                if(isNullOrNaN(x)) {
                    onChange(x);
                } else {
                    onChange((Number(x) / 100).toFixed(4));
                }
            } }
            inputPropsStyle={ inputPropsStyle }
            renderFunc={ x => `${isNullOrNaN(x) ? '' : renderPercent(x / 100)}` }
            parserFunc={ x => isNullOrNaN(x) ? '' : parseFloat(value * 100).toFixed(2) }
            variant={ variant }
            size={ size }
            disableBorder={ disableBorder }
            textAlign={ textAlign }
            activeType='number'
            label={ label }
            fullWidth={ fullWidth }
            placeholder={ placeholder }
        />
    );
}

PercentInputComponent.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    disableBorder: PropTypes.bool,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    size: PropTypes.string,
    textAlign: PropTypes.string,
    inputPropsStyle: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    fullWidth: PropTypes.bool,
};
