import React from 'react';
import propTypes from 'prop-types';
import AftalerTable from './AftalerTable.js';

const PensionsInfoAftalerView = ({ aftaler }) => {
    return (
        <div>
            <AftalerTable aftaler={ aftaler }></AftalerTable>
        </div>
    );
};

PensionsInfoAftalerView.propTypes = {
    aftaler: propTypes.array.isRequired,
    profil: propTypes.object.isRequired,
};

export default PensionsInfoAftalerView;
