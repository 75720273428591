import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { List, ListItem } from '@mui/material';
import AssetsTable from '../InvestComponent/AssetsTable.js';
import CompareCostsTable from '../InvestComponent/CompareCostsTable.js';
import EstimatedReturnsGraph from '../InvestComponent/EstimatedReturnsGraph.js';
import CostDifferenceBarChart from '../InvestComponent/CostDifferenceBarChart.js';
import PageTemplate from './Pages/PageTemplate/PageTemplate.js';
import FrontPage from './Pages/FrontPage/FrontPage.js';
import { getAssetsSum } from '../../utils/calc.js';
import { isOneTimeManagmentCostLargerThanZero, isNullOrNaN } from '../../utils/helperFunctions.js';
import { renderPercent, renderKr, renderExcessTurnoverYears, getExcessTurnoverYears } from '../../utils/renderingService.js';
import { getFundInfoByIdAsync } from '../../apiRepository/investApiManager.js';
import AllocationBarChart from '../Charts/AllocationBarChart.js';
import PerformanceBarChart from '../Charts/PerformanceBarChart.js';
import PerformanceTable from '../Charts/PerformanceTable.js';
import RiskBarComponent from '../Charts/RiskBar/RiskBarComponent.js';
import MifidCostTable from '../InvestComponent/MifidCostTable.js';
import InvestCombinedCountryExposurePieCharts from '../InvestComponent/InvestCombinedCountryExposurePieCharts.js';
import { defaultTransactionCostIndirect, defaultEstimatedReturn, defaultStandardDeviation } from '../../defaultValues.js';

export default function InvestCheckReport(props) {
    const themeColors = useSelector(state => state.site.theme.colors);
    const colors = {
        current: themeColors.extras[0],
        suggested: themeColors.primary,
    };
    const { state } = props;
    const { investData, variation, partnerData, config } = state;
    const { result, comment, showCurrentReturns, showNewReturns, showSecondStdDev, calculationSettings } = investData;
    const {
        input, costComparison,
        currentAllocation, targetAllocation,
        currentPortfolioPerformance, targetPortfolioPerformance,
        currentPortfolioVolatility, targetPortfolioVolatility,
        currentPortfolioMifidCost, targetPortfolioMifidCost,
        currentPortfolioCountryExposure, targetPortfolioCountryExposure,
    } = result;
    const { currentPortfolio, targetPortfolio, oneTimeManagementCost } = input;
    const { targetCostsFirstYearInPercent, targetCostsInPercent, currentCostsInPercent } = costComparison;
    const [assetsDetails, setAssetsDetails] = useState([]);
    const hasOneTimeManagmentCost = isOneTimeManagmentCostLargerThanZero(oneTimeManagementCost);
    const showCountryExposure = (config?.features?.includes('deep_dive') ?? false)
        && (
            ((currentPortfolioCountryExposure?.result?.equityLongCountryExposure?.filter(x => x.value > 0).length ?? 0) !== 0)
            || ((targetPortfolioCountryExposure?.result?.equityLongCountryExposure?.filter(x => x.value > 0).length ?? 0) !== 0)
        );

    useEffect(() => {
        async function getAssetDetails() {
            const assetsInfo = await Promise.all(currentPortfolio.assets.concat(targetPortfolio.assets).map(async asset => {
                try {
                    const assetInfo = await getFundInfoByIdAsync(asset.id);
                    const customAaop = partnerData.customAssets?.find(customAsset => customAsset.id === asset.id)?.aaop;

                    return {
                        ...asset,
                        aaop: Number(assetInfo.mifid.danishAAOP),
                        customAaop,
                        transactionCostIndirect: Number(assetInfo.mifid.transactionCostIndirect),
                        frontLoad: Number(assetInfo.mifid.frontLoad),
                        deferLoad: Number(assetInfo.mifid.deferLoad),
                    };
                } catch (error) {
                    console.log(error);
                    return asset;
                }
            }));
            setAssetsDetails(assetsInfo);
            window.isReadyForPDF = true;
        }
        getAssetDetails();
    }, [currentPortfolio.assets, partnerData.customAssets, targetPortfolio.assets]);

    const portfolioSum = getAssetsSum(currentPortfolio.assets);
    const startYear = new Date().getFullYear();
    const endYear = startYear+ 10;

    const estimatedReturn = targetPortfolio.estimatedReturn && !Number.isNaN(targetPortfolio.estimatedReturn) ? Number(targetPortfolio.estimatedReturn) : defaultEstimatedReturn;
    const standardDeviation = targetPortfolio.standardDeviation && !Number.isNaN(targetPortfolio.standardDeviation) ? Number(targetPortfolio.standardDeviation) : defaultStandardDeviation;
    const estimatedPerformance = { average: estimatedReturn - targetCostsInPercent, standardDeviation };
    const currentPerformance = { average: estimatedReturn - currentCostsInPercent, standardDeviation }; // #TODO is this right?

    const showHistoricalPerformance = (calculationSettings?.selectedHistoricalPerformanceYears?.yearOne ?? true)
        || (calculationSettings?.selectedHistoricalPerformanceYears?.yearThree ?? true)
        || (calculationSettings?.selectedHistoricalPerformanceYears?.yearFive ?? true);

    const currentMifidCost = currentPortfolioMifidCost?.result;
    const targetMifidCost = targetPortfolioMifidCost?.result;
    const useMifidData = input?.calculationSettings?.useMifidCostData ?? false;
    const showMifidOmk = useMifidData && currentMifidCost && targetMifidCost;

    const currentExcludedFromHistPerfAssets = currentPortfolio.assets.filter(asset => !(asset.calculationSettings?.includeInHistoricalPerformance ?? true) && asset.value > 0);
    const targetExcludedFromHistPerfAssets = targetPortfolio.assets.filter(asset => !(asset.calculationSettings?.includeInHistoricalPerformance ?? true) && asset.value > 0);

    function checkForExcludedHistPerfAssets(assets) {
        const exludedResult = {
            anyExcluded: false,
            excludedPercentage: 0,
        };
        const excludedAssets = assets.filter(asset => !(asset.calculationSettings?.includeInHistoricalPerformance ?? true) && asset.value > 0);

        if(excludedAssets.length > 0) {
            exludedResult.anyExcluded = true;

            const totalValue = getAssetsSum(assets);
            const excludedValue = getAssetsSum(excludedAssets);
            exludedResult.excludedPercentage = excludedValue / totalValue;
        }

        return  exludedResult;
    }

    const targetExcludedFromHistPerfResult = checkForExcludedHistPerfAssets(targetPortfolio.assets);
    const currentExcludedFromHistPerfResult = checkForExcludedHistPerfAssets(currentPortfolio.assets);
    const intervals = Object.values(partnerData.defaultValues.columnGraphSavingYears).sort((a, b) => a - b);

    return (
        <div>
            <FrontPage { ...props } />
            { comment && comment !== '' && (
                <PageTemplate { ...props }>
                    <h2 style={{ textTransform: 'upperCase' }}>Bemærkninger til dit InvestTjek</h2>
                    <hr style={{ margin: '10px 0px' }} />
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                        { comment }
                    </div>
                </PageTemplate>
            ) }
            <PageTemplate { ...props }>
                <h2 style={{ textTransform: 'upperCase' }}>Nuværende portefølje:</h2>
                <hr style={{ margin: '10px 0px' }} />
                <AssetsTable
                    assets={ currentPortfolio.assets }
                    showRating
                    isCurrentPortfolio={ true }
                />
            </PageTemplate>
            { variation === 'analysis' ? (
                <PageTemplate { ...props }>
                    <h2 style={{ textTransform: 'upperCase' }}>Foreslået portefølje:</h2>
                    <hr style={{ margin: '10px 0px' }} />
                    <AssetsTable
                        assets={ targetPortfolio.assets }
                        showRating
                        isCurrentPortfolio={ false }
                    />
                </PageTemplate>
            ) : (
                <div />
            ) }
            { !useMifidData && (
                <>
                    <PageTemplate { ...props }>
                        <h2 style={{ textTransform: 'upperCase' }}>Besparelse i omkostninger:</h2>
                        <hr style={{ margin: '10px 0px' }} />
                        <CompareCostsTable data={ costComparison } />
                        { parseFloat(input.oneTimeManagementCost) ? (
                            <p>
                                *Tal i parentes angiver første års omkostninger inkl. opstartsomkostninger.
                            </p>
                        ) : <div /> }
                    </PageTemplate>
                    <PageTemplate { ...props }>
                        <h2 style={{ textTransform: 'upperCase' }}>Besparelse i omkostninger ved { renderPercent(estimatedReturn) } afkast</h2>
                        <hr style={{ margin: '10px 0px' }} />
                        <p>
                            Diagrammet viser besparelsen i omkostninger, baseret på et afkast på { renderPercent(estimatedReturn) }.
                            Til beregningen bruges de nuværende omkostninger ({ renderPercent(currentCostsInPercent) }),
                            og de foreslåede omkostninger
                            (
                            { parseFloat(oneTimeManagementCost) !== 0 && `hhv. ${renderPercent(targetCostsFirstYearInPercent)} og ` }
                            { renderPercent(targetCostsInPercent) }).
                        </p>
                        <div style={{ display:'flex', justifyContent:'center' }}>
                            <CostDifferenceBarChart
                                initialValue={ portfolioSum }
                                returnPerYear={ estimatedReturn }
                                currentCostsPerYear={ currentCostsInPercent }
                                newCostFirstYear={ targetCostsFirstYearInPercent }
                                newCostPerYear={ targetCostsInPercent }
                                startYear={ startYear }
                                intervals={ intervals }
                                height={ 550 }
                            />
                        </div>
                    </PageTemplate>
                </>
            ) }

            { showMifidOmk && (
                <>
                    <PageTemplate { ...props }>
                        <h2 style={{ textTransform: 'upperCase' }}>Besparelse i omkostninger:</h2>
                        <hr style={{ margin: '10px 0px' }} />
                        <MifidCostTable
                            currentCostResult={ currentMifidCost }
                            targetCostResult={ targetMifidCost }
                            hasOneTimeManagmentCost={ hasOneTimeManagmentCost }
                        />
                        { hasOneTimeManagmentCost ? (
                            <p>
                                *Tal i parentes angiver første års omkostninger inkl. opstartsomkostninger.
                            </p>
                        ) : <div /> }
                    </PageTemplate>
                    <PageTemplate { ...props }>
                        <h2 style={{ textTransform: 'upperCase' }}>Besparelse i omkostninger ved { renderPercent(estimatedReturn) } afkast</h2>
                        <hr style={{ margin: '10px 0px' }} />
                        <p>
                            Diagrammet viser besparelsen i omkostninger, baseret på et afkast på { renderPercent(estimatedReturn) }.
                            Til beregningen bruges de nuværende omkostninger ({ renderPercent(currentMifidCost.totalFeesInPercent.asFraction) }),
                            og de foreslåede omkostninger
                            (
                            { hasOneTimeManagmentCost && `hhv. ${renderPercent(targetMifidCost.totalFeesInPercentFirstYear.asFraction)} og ` }
                            { renderPercent(targetMifidCost.totalFeesInPercent.asFraction) }).
                        </p>
                        <div style={{ display:'flex', justifyContent:'center' }}>
                            <CostDifferenceBarChart
                                initialValue={ portfolioSum }
                                returnPerYear={ estimatedReturn }
                                currentCostsPerYear={ currentMifidCost.totalFeesInPercent.asFraction }
                                newCostFirstYear={ targetMifidCost.totalFeesInPercentFirstYear.asFraction }
                                newCostPerYear={ targetMifidCost.totalFeesInPercent.asFraction }
                                startYear={ startYear }
                                intervals={ intervals }
                                height={ 550 }
                            />
                        </div>
                    </PageTemplate>
                </>
            ) }
            <PageTemplate { ...props }>
                <h2 style={{ textTransform: 'upperCase' }}>Forventet udvikling ved { renderPercent(estimatedReturn) } afkast</h2>
                <hr style={{ margin: '10px 0px' }} />
                <p>
                    Grafen viser en prognose for udvikling i værdi for den forslåede portefølje,
                    baseret på et afkast på { renderPercent(estimatedReturn) } og en standard afvigelse på { renderPercent(standardDeviation) }.
                </p>
                <div style={{ display:'flex', justifyContent:'center' }}>
                    <EstimatedReturnsGraph
                        portfolioSum={ portfolioSum }
                        startYear={ startYear }
                        endYear={ endYear }
                        returnPerYearSuggested={ estimatedPerformance }
                        returnPerYearCurrent={ currentPerformance }
                        showCurrentReturns={ showCurrentReturns ?? false }
                        showNewReturns={ showNewReturns ?? true }
                        show2Std={ showSecondStdDev ?? false }
                        height={ 550 }
                    />
                </div>
            </PageTemplate>
            { (showHistoricalPerformance && (targetPortfolioPerformance?.result || currentPortfolioPerformance?.result)
                && (targetPortfolioPerformance?.result.portfolioTotalReturnOneYear || currentPortfolioPerformance?.result.portfolioTotalReturnOneYear))
                && (
                    <PageTemplate { ...props }>
                        <h2 style={{ textTransform: 'upperCase' }}>Portefølje performance</h2>
                        <hr style={{ margin: '10px 0px' }} />
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                            <div>
                                <PerformanceBarChart
                                    targetPerformanceData={ targetPortfolioPerformance?.result ?? null }
                                    currentPerformanceData={ currentPortfolioPerformance?.result ?? null }
                                    annually={ false }
                                    showYearOne={ calculationSettings?.selectedHistoricalPerformanceYears?.yearOne ?? true }
                                    showYearThree={ calculationSettings?.selectedHistoricalPerformanceYears?.yearThree ?? true }
                                    showYearFive={ calculationSettings?.selectedHistoricalPerformanceYears?.yearFive ?? true }
                                />
                            </div>
                            <div>
                                <PerformanceBarChart
                                    targetPerformanceData={ targetPortfolioPerformance?.result ?? null }
                                    currentPerformanceData={ currentPortfolioPerformance?.result ?? null }
                                    total={ false }
                                    showYearOne={ calculationSettings?.selectedHistoricalPerformanceYears?.yearOne ?? true }
                                    showYearThree={ calculationSettings?.selectedHistoricalPerformanceYears?.yearThree ?? true }
                                    showYearFive={ calculationSettings?.selectedHistoricalPerformanceYears?.yearFive ?? true }
                                />
                            </div>
                        </div>
                        <PerformanceTable
                            targetPerformanceData={ targetPortfolioPerformance?.result ?? null }
                            currentPerformanceData={ currentPortfolioPerformance?.result ?? null }
                            showYearOne={ calculationSettings?.selectedHistoricalPerformanceYears?.yearOne ?? true }
                            showYearThree={ calculationSettings?.selectedHistoricalPerformanceYears?.yearThree ?? true }
                            showYearFive={ calculationSettings?.selectedHistoricalPerformanceYears?.yearFive ?? true }
                        />
                        Ovenstående afkast er justeret for omkostninger.
                        { currentExcludedFromHistPerfResult.anyExcluded &&
                            <>
                                &nbsp;
                                Da visse af værdipapirerne i den eksisterende portefølje er relativt unge, er der ikke opgivet afkast-historik på papiret.
                                Det viste afkast er derfor afkastet på { renderPercent(1-currentExcludedFromHistPerfResult.excludedPercentage) }
                                &nbsp;af den eksisterende portefølje og ikke hele porteføljen.
                            </>
                        }
                        { targetExcludedFromHistPerfResult.anyExcluded &&
                            <>
                                &nbsp;
                                Da visse af værdipapirerne i den anbefalede portefølje er relativt unge, er der ikke opgivet afkast-historik på papiret.
                                Det viste afkast er derfor afkastet på { renderPercent(1-targetExcludedFromHistPerfResult.excludedPercentage) }
                                &nbsp;af den anbefalede porteføljen og ikke hele porteføljen.
                            </>
                        }
                        { (currentExcludedFromHistPerfResult.anyExcluded || targetExcludedFromHistPerfResult.anyExcluded) &&
                            <>
                                <br />
                                Afkast-sammenligningen mellem den eksisterende portefølje og den anbefalede portefølje er derfor ikke fuldstændig og dermed forbundet med usikkerhed!
                            </>
                        }
                    </PageTemplate>
                ) }
            { (!isNullOrNaN(currentPortfolioVolatility?.result?.volatility) || !isNullOrNaN(targetPortfolioVolatility?.result?.volatility)) && (
                <PageTemplate { ...props }>
                    <h2 style={{ textTransform: 'upperCase' }}>Risko analyse</h2>
                    <hr style={{ margin: '10px 0px' }} />
                    <p>
                        Risikoskalaen viser porteføljernes risiko på en 7-trins skala hvor 1 repræsenterer en lav risiko og 7 repræsenterer en høj risiko. Kategori 1 er ikke en risikofri investering.
                        Porteføljernes placering på risikoskalaen er bestemt af udsvingene af porteføljernes ugentlige afkast.
                        Store udsving er lig med høj risiko og en placering til højre på risikoskalaen. Små udsving indebærer lavere risiko og en placering til venstre på risikoskalaen.
                    </p>
                    <RiskBarComponent
                        currentVolatility={ currentPortfolioVolatility?.result?.volatility }
                        targetVolatility={ targetPortfolioVolatility?.result?.volatility }
                        currentColor={ colors.current }
                        targetColor={ colors.suggested }
                    />
                </PageTemplate>
            ) }
            { (currentAllocation || targetAllocation) && (
                <PageTemplate { ...props }>
                    <h2 style={{ textTransform: 'upperCase' }}>Portefølje allokeringer</h2>
                    <hr style={{ margin: '10px 0px' }} />
                    <div style={{ display: 'flex', flexDirection: 'row', width: 'auto', justifyContent: 'space-around' }}>
                        <div>
                            <AllocationBarChart
                                allocationData={ currentAllocation }
                                name='Nuværende portefølje'
                            />
                        </div>
                        <div>
                            <AllocationBarChart
                                allocationData={ targetAllocation }
                                name='Foreslået portefølje'
                            />
                        </div>
                    </div>
                </PageTemplate>
            ) }
            { showCountryExposure && (
                <PageTemplate { ...props }>
                    <h2 style={{ textTransform: 'upperCase' }}>Top 10 lande eksponering</h2>
                    <hr style={{ margin: '10px 0px' }} />
                    <p>
                        Nedenfor vises de top 10 lande som den nuværende og foreslået portefølje er eksponeret i mod.
                        Bemærk: Landedata er baseret på den reskalerede lange position for aktiebeholdningen.
                    </p>
                    <InvestCombinedCountryExposurePieCharts currentPortfolioCountryExposure={ currentPortfolioCountryExposure } targetPortfolioCountryExposure={ targetPortfolioCountryExposure } />
                </PageTemplate>
            ) }
            <PageTemplate { ...props }>
                <h4>Ansvarsfraskrivelse:</h4>
                <p>
                    Ovennævnte analyse er udarbejdet ud fra oplysninger via morningstar.dk* og eventuelle fejl kan opstå, hvilke vi ikke kan tage ansvar for. Dernæst er der en vis usikkerhed i
                    forhold til løbende handelsaktivitet fra kundens bank. Derfor skal ovennævnte analyse ses som en indikation af kundens samlede omkostninger.
                </p>
                <h4>Beregningsforudsætninger for nuværende portefølje:</h4>
                <List>
                    <ListItem>Årlige Pleje-omkostning: { renderPercent(currentPortfolio.managementCostRatio) }</ListItem>
                    <ListItem>Årlige omsætningshastighed i år: { renderExcessTurnoverYears(getExcessTurnoverYears(currentPortfolio.excessTurnoverRatio)) }</ListItem>
                    { partnerData.customAssets !== undefined && partnerData.customAssets.length > 0 && (() => {
                        const customAssetsInCurrentPortfolio = partnerData.customAssets.filter(customAsset => currentPortfolio.assets.some(currentAsset => currentAsset.id === customAsset.id));
                        if(customAssetsInCurrentPortfolio.length > 0) {
                            return customAssetsInCurrentPortfolio.filter(customAsset => !isNullOrNaN(customAsset.aaop)).map(customAsset => (
                                <ListItem key={ customAsset.id }>
                                    ÅOP for { customAsset.name } { customAsset.isin } er korrigeret til { renderPercent(customAsset.aaop) }
                                    &nbsp;fra { renderPercent(assetsDetails.find(asset => asset.id === customAsset.id)?.aaop) }
                                </ListItem>
                            ));
                        }
                    })() }
                    { partnerData.customAssets !== undefined && partnerData.customAssets.length > 0 && (() => {
                        const customAssetsInCurrentPortfolio = partnerData.customAssets.filter(customAsset => currentPortfolio.assets.some(currentAsset => currentAsset.id === customAsset.id));
                        if(customAssetsInCurrentPortfolio.length > 0) {
                            return customAssetsInCurrentPortfolio.filter(customAsset => customAsset.allocation).map(customAsset => (
                                <ListItem key={ customAsset.id }>
                                    Aktivfordelingen for { customAsset.name } { customAsset.isin } er korrigeret til aktier: { renderPercent(customAsset.allocation.stock) },
                                    obligationer: { renderPercent(customAsset.allocation.bond) }, kontanter: { renderPercent(customAsset.allocation.cash) }
                                    &nbsp;og andet: { renderPercent(customAsset.allocation.other) }
                                </ListItem>
                            ));
                        }
                    })() }
                    { currentExcludedFromHistPerfAssets.length > 0 &&
                    currentExcludedFromHistPerfAssets.map(asset => (
                        <ListItem key={ asset.id }>
                            { asset.name } { asset.isin } er udeladt fra den historisk afkast beregning!
                        </ListItem>
                    ))
                    }
                </List>
                <h4>
                    Beregningsforudsætningerfor porteføljeforslag:
                </h4>
                <List>
                    <ListItem>Årlige pleje-omkostning: { renderPercent(targetPortfolio.managementCostRatio) }</ListItem>
                    <ListItem>Opstartsomkostninger: { renderKr(oneTimeManagementCost, 3, 0) }</ListItem>
                    <ListItem>Årlige omsætningshastighed i år: { renderExcessTurnoverYears(getExcessTurnoverYears(targetPortfolio.excessTurnoverRatio)) }</ListItem>
                    { partnerData.customAssets !== undefined && partnerData.customAssets.length > 0 && (() => {
                        const customAssetsInTargetPortfolio = partnerData.customAssets.filter(customAsset => targetPortfolio.assets.some(targetAsset => targetAsset.id === customAsset.id));
                        if(customAssetsInTargetPortfolio.length > 0) {
                            return customAssetsInTargetPortfolio.filter(customAsset => !isNullOrNaN(customAsset.aaop)).map(customAsset => (
                                <ListItem key={ customAsset.id }>
                                    ÅOP for { customAsset.name } { customAsset.isin } er korrigeret til { renderPercent(customAsset.aaop) }
                                    &nbsp;fra { renderPercent(assetsDetails.find(asset => asset.id === customAsset.id)?.aaop) }
                                </ListItem>
                            ));
                        }
                    })() }
                    { partnerData.customAssets !== undefined && partnerData.customAssets.length > 0 && (() => {
                        const customAssetsInTargetPortfolio = partnerData.customAssets.filter(customAsset => targetPortfolio.assets.some(targetAsset => targetAsset.id === customAsset.id));
                        if(customAssetsInTargetPortfolio.length > 0) {
                            return customAssetsInTargetPortfolio.filter(customAsset => customAsset.allocation).map(customAsset => (
                                <ListItem key={ customAsset.id }>
                                    Aktivfordelingen for { customAsset.name } { customAsset.isin } er korrigeret til aktier: { renderPercent(customAsset.allocation.stock) },
                                    obligationer: { renderPercent(customAsset.allocation.bond) }, kontanter: { renderPercent(customAsset.allocation.cash) }
                                    &nbsp;og andet: { renderPercent(customAsset.allocation.other) }
                                </ListItem>
                            ));
                        }
                    })() }
                    { targetExcludedFromHistPerfAssets.length > 0 &&
                        targetExcludedFromHistPerfAssets.map(asset => (
                            <ListItem key={ asset.id }>
                                { asset.name } { asset.isin } er udeladt fra den historisk afkast beregning!
                            </ListItem>
                        ))
                    }
                </List>
                <p>
                    * I de tilfælde hvor indirekte transaktionsomkostning er uoplyst anvendes et estimat på { renderPercent(partnerData.defaultValues.transactionCostIndirect
                        ?? defaultTransactionCostIndirect) }
                </p>
            </PageTemplate>
        </div>
    );

}

InvestCheckReport.propTypes = {
    state: PropTypes.object.isRequired,
};
