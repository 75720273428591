import React, { useCallback } from 'react';
import propTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle , styled } from '@mui/material';
import { useDispatch } from 'react-redux';

import { useDropzone } from 'react-dropzone';
import { uploadFile } from '../../actions/customerActions.js';
import { updateProcessField } from '../../actions/processActions.js';

const Dropzone = styled('div')(()=> ({
    height: '100px',
    lineHeight: '90px',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderColor: '#AAA',
    border: 1,
    background: 'repeating-linear-gradient(-55deg, #CCC, #CCC 10px, #DDD 10px, #DDD 20px)',
    textAlign: 'center',
    verticalAlign: 'middle',
}));

export default function UploadPensionsInfoModal({ open, handleClose, customerId }) {
    const [file, setFile] = React.useState(undefined);
    const dispatch = useDispatch();

    const handleSave = async () => {
        handleClose();
        file.fileType = 'PensionsInfoFile';
        const { fileId } = await dispatch(uploadFile(customerId, file, { scrape: true }));
        await dispatch(updateProcessField(`files.${fileId}`, { fileId, scraping: true }));
    };

    const onDrop = useCallback(acceptedFiles => {
        setFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div>
            <Dialog open={ open } onClose={ handleClose } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Upload PensionsInfo</DialogTitle>
                <DialogContent>
                    <DialogContentText>Upload kundens PensionsInfo-fil. Filen vil herefter blive scrapet automatisk.</DialogContentText>
                    <Dropzone { ...getRootProps() }>
                        <input { ...getInputProps() } />
                        { file && (
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <p style={{ margin: 0, fontSize: 14 }}>{ file.name }</p>
                            </div>
                        ) }
                        { (!file && isDragActive) ? (
                            'Træk en fil hertil for at uploade'
                        ) : (
                            'Træk en fil hertil for at uploade, eller klik for at vælge fil'
                        ) }
                    </Dropzone>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleClose } color="secondary">
                        Fortryd
                    </Button>
                    <Button onClick={ handleSave } disabled={ !file }>
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

UploadPensionsInfoModal.propTypes = {
    open: propTypes.bool.isRequired,
    handleClose: propTypes.func.isRequired,
    customerId: propTypes.string.isRequired,
};
