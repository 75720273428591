import React from "react";
import PropTypes from "prop-types";
import { useTheme, Card } from "@mui/material";

import { useWindowSize } from "../../../hooks/index.js";

const PageContent = (props) => {
  const { padded = true, children } = props;
  const theme = useTheme();
  const { width } = useWindowSize();
  const isMobile = width < theme.breakpoints.values.md;
  return (
    <Card
      style={{
        padding: !isMobile && padded && "20px",
      }}
    >
      {children}
    </Card>
  );
};

PageContent.propTypes = {
  padded: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PageContent;
