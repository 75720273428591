import initialState from './initialState.js';
import * as types from '../actions/actionTypes.js';

// advisor datastructure
// {
//    name: String
// }

export default function advisorDataReducer(state = initialState.advisorData, action) {
    switch (action.type) {
        case types.ADVISOR_DATA_UPDATE_REQUEST:
            return { ...state, isFetching: true };

        case types.ADVISOR_DATA_UPDATED:
            return { ...state,
                isFetching: false,
                advisors: {
                    ...state.advisors,
                    [action.advisorData.advisorId]: { ...action.advisorData },
                },
                advisor: action.advisorData,
            };

        case types.ADVISOR_DATA_ADDED:
            return { ...state,
                isFetching: false,
                advisors: {
                    ...state.advisors,
                    [action.advisorData.advisorId]: { ...action.advisorData },
                },
            };

        case types.ADVISOR_DATA_MULTIPLE_ADDED:
        {
            const newAdvisorsList = action.advisorData.reduce((acc, advisor) => ({ ...acc, [advisor.advisorId]: advisor }), { ...state.advisors });
            return { ...state,
                isFetching: false,
                advisors: newAdvisorsList,
            };
        }

        case types.ADVISOR_DATA_SET_CURRENT:
            return {
                ...state,
                advisor: state.advisors[action.advisorId],
            };

        default:
            return state;
    }
}
