import initialState from './initialState.js';
import * as types from '../actions/actionTypes.js';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function projectionReducer(state = initialState.projection, action) {
    switch (action.type) {
        case types.USER_RESET_STATE: {
            return { ...initialState.projection };
        }
        case types.PENSION_CALCULATION_REQUESTED: {
            return { ...state, isCalculating: true };
        }
        case types.PENSION_CALCULATION_SUCCESS: {
            return { ...state, isCalculating: false, optimizationResult: action.optimizationResult };
        }
        case types.PENSION_CALCULATION_FAIL: {
            return { ...state, isCalculating: false };
        }
        default:
            return state;
    }
}
