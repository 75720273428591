import initialState from './initialState.js';
import * as types from '../actions/actionTypes.js';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function customerDataReducer(state = initialState.customerData, action) {
    switch (action.type) {
        case types.ADVISOR_DATA_UPDATED: {
            return { ...state };
        }
        case types.CUSTOMER_DATA_UPDATE_REQUEST: {
            return { ...state, isFetching: true };
        }
        case types.CUSTOMER_DATA_ADDED: {
            return {
                ...state,
                customers: [
                    action.customerData,
                    ...state.customers,

                ],
                isFetching: false,
            };
        }
        case types.CUSTOMER_DATA_UPDATED: { // eslint-disable-line no-case-declarations
            // eslint-disable-next-line no-case-declarations
            const index = state.customers.findIndex(customer => customer.customerId === action.customerData.customerId);
            return {
                ...state,
                customers: [
                    ...state.customers.slice(0, index),
                    action.customerData,
                    ...state.customers.slice(index + 1),
                ],
                isFetching: false,
            };
        }
        case types.CUSTOMER_FILE_UPLOAD_REQUEST: {
            const { customerId } = action.payload;
            const index = state.customers.findIndex(customer => customer.customerId === customerId);
            const customer = state.customers[index];
            const { files } = customer;

            return {
                ...state,
                customers: [
                    ...state.customers.slice(0, index),
                    {
                        ...customer,
                        files: [...files, { loading: 'Uploader fil' }],
                    },
                    ...state.customers.slice(index + 1),
                ],
                isFetching: false,
            };
        }
        case types.CUSTOMER_FILE_SCRAPE_REQUEST: {
            const { customerId, fileId } = action.payload;
            const index = state.customers.findIndex(customer => customer.customerId === customerId);
            const customer = state.customers[index];
            const { files } = customer;
            const fileIndex = files.findIndex(file => file.fileId === fileId);
            const file = files[fileIndex];

            return {
                ...state,
                customers: [
                    ...state.customers.slice(0, index),
                    {
                        ...customer,
                        files: [
                            ...files.slice(0, fileIndex),
                            { ...file, loading: 'Scraper fil' },
                            ...files.slice(fileIndex + 1),
                        ],
                    },
                    ...state.customers.slice(index + 1),
                ],
                isFetching: false,
            };
        }
        case types.CUSTOMER_FILE_DELETE_REQUEST: {
            const { customerId, fileId } = action.payload;
            const index = state.customers.findIndex(customer => customer.customerId === customerId);
            const customer = state.customers[index];
            const { files } = customer;
            const fileIndex = files.findIndex(file => file.fileId === fileId);
            const file = files[fileIndex];

            return {
                ...state,
                customers: [
                    ...state.customers.slice(0, index),
                    {
                        ...customer,
                        files: [
                            ...files.slice(0, fileIndex),
                            { ...file, loading: 'Sletter fil' },
                            ...files.slice(fileIndex + 1),
                        ],
                    },
                    ...state.customers.slice(index + 1),
                ],
                isFetching: false,
            };
        }
        case types.CUSTOMER_ALL_DATA_UPDATED:
            return {
                ...state,
                customers: action.customers,
                isFetching: false,
            };
        case types.USER_RESET_STATE: {
            return { ...initialState.customerData, customerId: action.customerId, isFetching: true, updated: true };
        }
        case types.DOCUMENT_DATA_UPDATED: {
            return { ...state, numberOfDocs: state.numberOfDocs + 1 };
        }
        default:
            return state;
    }
}
