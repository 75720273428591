import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './store/configureStore.js';
import 'toastr/build/toastr.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import './index.css';
import App from './components/App.js';

const store = configureStore();
export default store;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <Provider store={ store }>
            <App />
        </Provider>
    </BrowserRouter>);
