import React from "react";
import PropTypes from 'prop-types';
import DefaultFooter from './DefaultFooter.js';

export default function DannebroginvestFooter({ alternativeFooter = false }) {
    return <DefaultFooter alternativeFooter={ alternativeFooter } footerText='Dannebrog Invest | Jernbanegade 23, 4000 Roskilde | CVR: 39763303'/>;
}

DannebroginvestFooter.propTypes = {
    alternativeFooter: PropTypes.bool,
};

