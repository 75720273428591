import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Dialog, AppBar , Typography, IconButton } from '@mui/material';

import { Close as CloseIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';

import { getFileUrl } from '../../../../actions/customerActions.js';

const FileDialog = ({ file, onClose }) => {
    const dispatch = useDispatch();
    const [url, setUrl] = useState(file?.url);


    useEffect(() => {
        if (!file) return setUrl();
        const run = async () => {
            let fileUrl;
            if (file.url) {
                fileUrl = file.url;
            } else {
                fileUrl = await dispatch(getFileUrl(file));
            }
            setUrl(fileUrl);
        };
        run();
    }, [dispatch, file]);

    const openInNewWindow = () => {
        window.open().document.write(`<body style="margin: 0;"><iframe src="${url}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe></body>`);
    };

    if (!file) return null;

    return (
        <Dialog
            fullScreen
            onClose={ onClose }
            open
        >
            <AppBar position='fixed' color='secondary'>
                <div style={{ marginLeft: 15, marginRight: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
                    <Typography variant={ 'h4' }>
                        { file.fileName }
                    </Typography>
                    <div>
                        <IconButton color='primary' onClick={ openInNewWindow } size="large">
                            <OpenInNewIcon fontSize="large"/>
                        </IconButton>
                        <IconButton color='primary' onClick={ onClose } size="large">
                            <CloseIcon fontSize="large"/>
                        </IconButton>
                    </div>
                </div>
            </AppBar>
            <iframe
                title={ file.originalFileName }
                style={{ position: 'absolute', top: '60px', border: 0, height: 'calc(100% - 60px)', width: '100%' }}
                src={ url }
            />
        </Dialog>
    );
};


FileDialog.propTypes = {
    file: PropTypes.object,
    onClose: PropTypes.func,
};

export default FileDialog;
