import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import { isNullOrNaN } from '../../../utils/helperFunctions.js';
import styles  from './RiskBarComponent.module.css';


export default function RiskBarComponent({ currentVolatility, targetVolatility, currentColor, targetColor, style, smallIndicator }) {
    const [anchorElTop, setAnchorElTop] = useState(null);
    const [anchorElBottom, setAnchorElBottom] = useState(null);
    const handlePopoverOpen = (event) => {
        if (event.currentTarget.id === 'top')
            setAnchorElTop(event.currentTarget);
        else 
            setAnchorElBottom(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorElTop(null);
        setAnchorElBottom(null);
    };
    const openTopPopover = Boolean(anchorElTop);
    const openBottomPopover = Boolean(anchorElBottom);
    
    const rangeArray = useMemo(() => {
        const volatilityIntervals = [0, 0.005, 0.02, 0.05, 0.1, 0.15, 0.25, 0.4];
        const renderTooltip = (bottom, label) => (
            <>
                <div
                    className={ `${styles.indicator} ${bottom ? `${styles.rotate180} ${styles.bottom}` : ''}` }
                    style={{ backgroundColor: `${bottom ? targetColor : currentColor}`, borderTopColor: `${bottom ? targetColor : currentColor}`, padding: `${smallIndicator ? '0' : ''}`, height: `${smallIndicator ? '0' : ''}`, top: `${smallIndicator ? (`${bottom ? '59px' : '-29px'}`) : ''}` }}
                    aria-owns={(bottom ? openBottomPopover : openTopPopover) ? `mouse-over-popover-${bottom}` : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    id={bottom ? 'bottom' : 'top'}
                >
                    <p className={ `${styles.riskFonts} ${bottom ? `${styles.rotate180}` : ''} ` } style={{ display: `${smallIndicator ? 'none' : ''}` }}>{ label }</p>
                </div>
                { smallIndicator &&
                    <Popover
                        id={`mouse-over-popover-${bottom}`}
                        sx={{ pointerEvents: 'none', }}
                        open={bottom ? openBottomPopover : openTopPopover}
                        anchorEl={bottom? anchorElBottom: anchorElTop}
                        anchorOrigin={{ vertical: `${bottom ? 'bottom' : 'top'}`, horizontal: 'center', }}
                        transformOrigin={{ vertical: `${bottom ? 'bottom' : 'top'}`, horizontal: 'center', }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <span>{ label }</span>
                    </Popover>
                }
            </>
        );

        const elementArr = [];
        const root = document.documentElement;

        if (smallIndicator)
            root.style.setProperty('--indicator-width', `0px`);
        else
            root.style.removeProperty('--indicator-width');

        volatilityIntervals.forEach((upperBoundary, key, array) => {
            if(key === 0) return; // skip first value since it is the lowest bondary

            const lowerBoundary = array[key - 1];
            let printCurrentIndicator = false;
            let printTargetIndicator = false;

            if(!isNullOrNaN(currentVolatility) && lowerBoundary <= currentVolatility && ((array.length-1) === key || currentVolatility < upperBoundary) ) {
                const difference = upperBoundary - lowerBoundary;
                printCurrentIndicator = true;

                let percentage = Math.round((currentVolatility - lowerBoundary) / difference * 100);
                percentage = percentage > 100 ? 100 : percentage;
                // set left value to position tooltip
                root.style.setProperty('--left-value-current', `${percentage}%`);
            }

            if(!isNullOrNaN(targetVolatility) && lowerBoundary <= targetVolatility && ((array.length-1) === key || targetVolatility < upperBoundary) ) {
                const difference = upperBoundary - lowerBoundary;
                printTargetIndicator = true;

                let percentage = Math.round((targetVolatility - lowerBoundary) / difference * 100);
                percentage = percentage > 100 ? 100 : percentage;
                // set left value to position tooltip
                root.style.setProperty('--left-value-target', `${percentage}%`);
            }

            elementArr.push(
                <div className={ styles.item } key={ key }
                >
                    <p className={ `${styles.riskFonts} ${styles.riskLevel}` }>{ key }</p>
                    { printCurrentIndicator && renderTooltip(false, 'Nuværende') }
                    { printTargetIndicator && renderTooltip(true, 'Foreslået') }
                </div>,
            );
        });

        return elementArr;
    }, [targetColor, currentColor, currentVolatility, targetVolatility, anchorElTop, anchorElBottom, openTopPopover, openBottomPopover, smallIndicator]);

    return (
        <div className={ styles.potential__range } style={{ ...style }}>
            <div className={ `${styles.range} ${isNullOrNaN(currentVolatility) && isNullOrNaN(targetVolatility) ? `${styles.greyscale}` : '' }` } style={{ marginBottom: `${smallIndicator ? '0' : ''}` }} >{ rangeArray }</div>
            { isNullOrNaN(currentVolatility) && <><br/><p>Der var ikke nok data tilgængelig til at beregne risikoen for den nuværende portefølje!</p></> }
            { isNullOrNaN(targetVolatility) && <p>Der var ikke nok data tilgængelig til at beregne risikoen for den foreslået portefølje!</p> }
        </div>
    );
}

RiskBarComponent.propTypes = {
    currentVolatility: PropTypes.number,
    targetVolatility: PropTypes.number,
    currentColor: PropTypes.any,
    targetColor: PropTypes.any,
    style: PropTypes.object,
    smallIndicator: PropTypes.any,
};
