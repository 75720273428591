import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, List, ListItem } from "@mui/material";
import {
  Cell,
  BarChart,
  Bar,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { renderPercent } from "../../utils/renderingService.js";

export default function AllocationBarChart(props) {
  const {
    allocationData = null,
    name,
    showLegend = true,
    showName = true,
  } = props;
  if (!allocationData) {
    return (
      <>
        <Typography variant="h5" gutterBottom>
          {name}
        </Typography>
        <p>Data ikke tilgængelig!</p>
      </>
    );
  }

  const allocation = [
    {
      name: "Aktier",
      value:
        allocationData.result.stock.longPosition -
        allocationData.result.stock.shortPosition,
      color: "#4582f5",
    },
    {
      name: "Obligationer",
      value:
        allocationData.result.bond.longPosition -
        allocationData.result.bond.shortPosition,
      color: "#fc383b",
    },
    {
      name: "Kontanter",
      value:
        allocationData.result.cash.longPosition -
        allocationData.result.cash.shortPosition,
      color: "#ffd04f",
    },
    {
      name: "Andet",
      value:
        allocationData.result.other.longPosition -
        allocationData.result.other.shortPosition,
      color: "#77bf3d",
    },
  ];

  const anyNegative = allocation.some((x) => x.value < 0);

  return (
    <>
      {showName && (
        <Typography variant="h5" gutterBottom paragraph>
          {name}
        </Typography>
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <BarChart
            width={400}
            height={200}
            data={allocation}
            layout="vertical"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis dataKey="name" type="category" />
            <XAxis
              tickFormatter={(value) => renderPercent(value)}
              type="number"
              domain={[anyNegative ? -1 : 0, 1]}
            />
            <Tooltip
              label="Værdi"
              formatter={(value) => renderPercent(value)}
            />
            {/* <Legend /> */}
            <ReferenceLine x={0} stroke="#000" />
            <Bar
              dataKey="value"
              fill="#8884d8"
              isAnimationActive={false}
              barSize={25}
            >
              {allocation.map((entry) => (
                <Cell key={entry.name} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </Grid>
        {showLegend && (
          <Grid item>
            <List style={{ padding: 0 }}>
              {allocation.map((row) => {
                const { color } = row;
                return (
                  <ListItem key={row.name} style={{ padding: 0 }}>
                    <span style={{ fontSize: "150%" }}>
                      <span style={{ color }}>&#9673;</span> {row.name}:{" "}
                      {renderPercent(row.value)}
                    </span>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        )}
      </Grid>
    </>
  );
}

AllocationBarChart.propTypes = {
  allocationData: PropTypes.object,
  name: PropTypes.string.isRequired,
  showLegend: PropTypes.bool,
  showName: PropTypes.bool,
};
