import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container } from "@mui/material";
import { pensionAnalysis } from "../../apiRepository/tealCalculationApi.js";
import PensionSavingsGraph from "./PensionSavingsGraph.js";
import PensionPayoutsGraph from "./PensionPayoutsGraph.js";
import LoadingPage from "../LoadingPage.js";
import {
  initializePensionCheckResult,
  getSuggestionHeader,
  getSuggestionSummary,
  getPensionDetails,
} from "../../utils/pensionCheck.js";

const PensionAnalysis = ({ process }) => {
  const [analysis, setAnalysis] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!process) return;
    async function fetchData() {
      // Do not rerun calculation while scraping
      setLoading(true);
      try {
        const result = await pensionAnalysis(Object.values(process.files)[0]);
        setAnalysis(result);
      } catch (error) {
        setAnalysis({ error });
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [process, process.files]);

  if (!process) return null;
  const { files } = process;
  const scrapes = Object.values(files).map((file) => file.metadata.content);

  if (loading || !analysis) {
    return (
      <div style={{ margin: 50 }}>
        <LoadingPage />
      </div>
    );
  }

  const { suggestions } = initializePensionCheckResult(analysis.suggestions);

  return (
    <Container style={{ padding: 30 }}>
      <div style={{ width: "100%", height: 500 }}>
        <h2>Opsparing frem til pension</h2>
        <PensionSavingsGraph
          data={analysis.projection.savingsTimeSeries}
          scrapes={scrapes}
          keyFigures={analysis.keyFigures}
        />
      </div>
      <div style={{ width: "100%", height: 500, marginTop: 50 }}>
        <h2>Udbetalinger som pensioneret</h2>
        <PensionPayoutsGraph payouts={analysis.payouts} salary={undefined} />
      </div>
      <div style={{ width: "100%", marginTop: 50 }}>
        <h2>Anbefalinger</h2>
        <div style={{ paddingLeft: 20 }}>
          {suggestions.map((suggestion, index) => (
            <div key={suggestion.id} style={{ marginBottom: 30 }}>
              <div id={`suggestion-${suggestion.type}`} data-index={index + 1}>
                <h3>{getSuggestionHeader(suggestion)}</h3>
              </div>

              <div style={{ paddingLeft: 20 }}>
                <div>
                  <p>
                    <strong>Forhold:</strong> {getSuggestionSummary(suggestion)}
                  </p>
                  <div className="providers">
                    {suggestion?.providers?.map((provider, i) => (
                      <div key={i}>
                        <strong>{provider.leverandoer}</strong>
                        <p>{getPensionDetails(provider)}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h4>{suggestion.actionTitle}</h4>
                </div>
                <div>
                  <p>{suggestion.actionText}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {!suggestions.length && <span>Der er ingen anbefalinger</span>}
      </div>
      <div style={{ width: "100%", marginTop: 50 }}>
        <h2>Ansvarsfraskrivelse</h2>
        Beregningerne til ovenstående grafer er udført af penly.dk og er dermed
        vejledende.
      </div>
    </Container>
  );
};

PensionAnalysis.propTypes = {
  process: PropTypes.object.isRequired,
};

export default PensionAnalysis;
