import initialState from './initialState.js';
import * as types from '../actions/actionTypes.js';

export default function eventsDataReducer(state = initialState.eventsData, action) {
    switch (action.type) {
        case types.EVENTS_DATA_UPDATE_REQUEST: {
            return { ...state, isFetching: true };
        }
        case types.EVENTS_DATA_UPDATED: {
            return { ...state, ...action.eventsData, isFetching: false };
        }
        case types.EVENT_DATA_UPDATED: {
                    const { events } = state;
                    const index = getIndex(events, action.eventData.eventId);
                    const updatedEvent = action.eventData;
        
                    return {
                        ...state,
                        events: [
                            ...events.slice(0, index),
                            updatedEvent,
                            ...events.slice(index + 1),
                        ],
                    };
                }
        default:
            return state;
    }
}

function getIndex(events, eventId) {
    return events.findIndex(event => event.eventId === eventId);
};
