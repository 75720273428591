import React from "react";
import PropTypes from "prop-types";
import PageActions from "../PageActions/index.js";

const PageName = (props) => (
  <PageActions id="page-name">{props.children}</PageActions>
);

PageName.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PageName;
