import initialState from './initialState.js';
import * as types from '../actions/actionTypes.js';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function documentDataReducer(state = initialState.documentData, action) {
    switch (action.type) {
        case types.USER_RESET_STATE: {
            return { ...initialState.documentData, isFetching: true };
        }
        case types.DOCUMENT_DATA_REQUESTED: {
            return { ...state, isFetching: true };
        }
        case types.DOCUMENT_DATA_SUCCESS: {
            return { ...state, isFetching: false };
        }
        case types.DOCUMENT_DATA_FAIL: {
            return { ...state, isFetching: false };
        }
        case types.DOCUMENT_DATA_UPDATED: {
            return { ...state, ...action.documentData, isFetching: false };
        }
        case types.DOCUMENT_DATA_LOADED: {
            return { ...state, ...action.documentData, isFetching: false };
        }
        case types.DOCUMENTS_UPDATED: {
            return { ...state, documents: [...state.documents, action.document], isFetching: false };
        }
        default:
            return state;
    }
}
