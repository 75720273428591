import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import {
  ComposedChart,
  Line,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

import { generateProjection } from "../../utils/calc.js";
import { renderNumber } from "../componentHelpers.js";

const EstimatedReturnsGraph = (props) => {
  const {
    portfolioSum,
    startYear,
    endYear,
    returnPerYearSuggested,
    returnPerYearCurrent,
    height = 400,
    showCurrentReturns = true,
    showNewReturns,
    show1Std = true,
    show2Std = false,
  } = props;
  const data = generateProjection(
    portfolioSum,
    startYear,
    endYear,
    returnPerYearSuggested,
    returnPerYearCurrent
  );

  const themeColors = useSelector((state) => state.site.theme.colors);

  const colors = {
    current: themeColors.extras[0],
    suggested: themeColors.primary,
  };

  const toKr = (value) => `${renderNumber(value)}`;
  const isAnimationActive = false;
  return (
    <ResponsiveContainer
      maxWidth="100%"
      height={height}
      margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
    >
      <ComposedChart data={data}>
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="year" type="number" domain={["dataMin", "dataMax"]} />
        <YAxis
          tickFormatter={toKr}
          width={90}
          type="number"
          domain={["auto", "auto"]}
        />
        <Tooltip
          formatter={(value, name) => [
            value.length > 1
              ? `${toKr(value[0])} - ${toKr(value[1])}`
              : toKr(value),
            name.includes("1 std. afv.") ? name : `${name} gennemsnit`,
          ]}
        />
        <Legend />
        {showNewReturns && (
          <Area
            isAnimationActive={isAnimationActive}
            name="Foreslået 1 std. afv."
            type="monotone"
            dataKey="std1_suggested"
            fill={colors.suggested}
            stroke={colors.suggested}
            style={{ opacity: 0.2 }}
            legendType="none"
          />
        )}
        {showNewReturns && show2Std && (
          <Area
            isAnimationActive={isAnimationActive}
            name="Foreslået 2 std. afv."
            type="monotone"
            dataKey="std2_suggested"
            fill={colors.suggested}
            stroke={colors.suggested}
            style={{ opacity: 0.2 }}
            legendType="none"
          />
        )}
        {showNewReturns && (
          <Line
            isAnimationActive={isAnimationActive}
            name="Foreslået"
            type="monotone"
            dataKey="average_suggested"
            fill={colors.suggested}
            stroke={colors.suggested}
          />
        )}
        {showCurrentReturns && show1Std && (
          <Area
            isAnimationActive={isAnimationActive}
            name="Nuværende 1 std. afv."
            type="monotone"
            dataKey="std1_current"
            fill={colors.current}
            stroke={colors.current}
            style={{ opacity: 0.2 }}
            legendType="none"
          />
        )}
        {showCurrentReturns && show2Std && (
          <Area
            isAnimationActive={isAnimationActive}
            name="Nuværende 2 std. afv."
            type="monotone"
            dataKey="std2_current"
            fill={colors.current}
            stroke={colors.current}
            style={{ opacity: 0.2 }}
            legendType="none"
          />
        )}
        {showCurrentReturns && (
          <Line
            isAnimationActive={isAnimationActive}
            name="Nuværende"
            type="monotone"
            dataKey="average_current"
            fill={colors.current}
            stroke={colors.current}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

EstimatedReturnsGraph.propTypes = {
  portfolioSum: PropTypes.number.isRequired,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  returnPerYearSuggested: PropTypes.object.isRequired,
  returnPerYearCurrent: PropTypes.object.isRequired,
  showCurrentReturns: PropTypes.bool.isRequired,
  showNewReturns: PropTypes.bool,
  height: PropTypes.number,
  show1Std: PropTypes.bool,
  show2Std: PropTypes.bool,
};

export default EstimatedReturnsGraph;
