import React from "react";
import PropTypes from "prop-types";
import {
  styled,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { renderPercent } from "../../utils/renderingService.js";
import { isNullOrUndefined } from "../../utils/helperFunctions.js";

const StyledTableCell = styled(TableCell)(() => ({
  borderLeft: "1px solid rgba(224, 224, 224, 1)",
}));

export default function PerformanceTable({
  targetPerformanceData,
  currentPerformanceData,
  showYearOne = true,
  showYearThree = true,
  showYearFive = true,
}) {
  if (
    (!targetPerformanceData && !currentPerformanceData) ||
    (isNullOrUndefined(targetPerformanceData?.portfolioTotalReturnOneYear) &&
      isNullOrUndefined(currentPerformanceData?.portfolioTotalReturnOneYear))
  ) {
    return <p>Data ikke tilgængelig!</p>;
  }

  const anyYearOneData =
    !isNullOrUndefined(currentPerformanceData?.portfolioTotalReturnOneYear) ||
    !isNullOrUndefined(targetPerformanceData?.portfolioTotalReturnOneYear);
  const anyYearThreeData =
    !isNullOrUndefined(
      currentPerformanceData?.portfolioTotalReturnThreeYears
    ) ||
    !isNullOrUndefined(targetPerformanceData?.portfolioTotalReturnThreeYears);
  const anyYearFiveData =
    !isNullOrUndefined(currentPerformanceData?.portfolioTotalReturnFiveYears) ||
    !isNullOrUndefined(targetPerformanceData?.portfolioTotalReturnFiveYears);

  const anyAnnualYearThreeData =
    !isNullOrUndefined(
      currentPerformanceData?.annualizedTotalReturnThreeYears
    ) ||
    !isNullOrUndefined(targetPerformanceData?.annualizedTotalReturnThreeYears);
  const anyAnnualYearFiveData =
    !isNullOrUndefined(
      currentPerformanceData?.annualizedTotalReturnFiveYears
    ) ||
    !isNullOrUndefined(targetPerformanceData?.annualizedTotalReturnFiveYears);

  return (
    <Paper variant="outlined">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {anyYearOneData && showYearOne && <TableCell>1 år</TableCell>}
            {anyAnnualYearThreeData && showYearThree && (
              <TableCell>3 år årligt</TableCell>
            )}
            {anyYearThreeData && showYearThree && (
              <TableCell>3 år i alt</TableCell>
            )}
            {anyAnnualYearFiveData && showYearFive && (
              <TableCell>5 år årligt</TableCell>
            )}
            {anyYearFiveData && showYearFive && (
              <TableCell>5 år i alt</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Nuværende portfølje</b>
            </TableCell>
            {anyYearOneData && showYearOne && (
              <StyledTableCell>
                {!isNullOrUndefined(
                  currentPerformanceData?.portfolioTotalReturnOneYear
                )
                  ? renderPercent(
                      currentPerformanceData?.portfolioTotalReturnOneYear
                    )
                  : "N/A"}
              </StyledTableCell>
            )}
            {anyAnnualYearThreeData && showYearThree && (
              <StyledTableCell>
                {!isNullOrUndefined(
                  currentPerformanceData?.annualizedTotalReturnThreeYears
                )
                  ? renderPercent(
                      currentPerformanceData?.annualizedTotalReturnThreeYears
                    )
                  : "N/A"}
              </StyledTableCell>
            )}
            {anyYearThreeData && showYearThree && (
              <StyledTableCell>
                {!isNullOrUndefined(
                  currentPerformanceData?.portfolioTotalReturnThreeYears
                )
                  ? renderPercent(
                      currentPerformanceData?.portfolioTotalReturnThreeYears
                    )
                  : "N/A"}
              </StyledTableCell>
            )}
            {anyAnnualYearFiveData && showYearFive && (
              <StyledTableCell>
                {!isNullOrUndefined(
                  currentPerformanceData?.annualizedTotalReturnFiveYears
                )
                  ? renderPercent(
                      currentPerformanceData?.annualizedTotalReturnFiveYears
                    )
                  : "N/A"}
              </StyledTableCell>
            )}
            {anyYearFiveData && showYearFive && (
              <StyledTableCell>
                {!isNullOrUndefined(
                  currentPerformanceData?.portfolioTotalReturnFiveYears
                )
                  ? renderPercent(
                      currentPerformanceData?.portfolioTotalReturnFiveYears
                    )
                  : "N/A"}
              </StyledTableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Foreslået portefølje</b>
            </TableCell>
            {anyYearOneData && showYearOne && (
              <StyledTableCell>
                {!isNullOrUndefined(
                  targetPerformanceData?.portfolioTotalReturnOneYear
                )
                  ? renderPercent(
                      targetPerformanceData?.portfolioTotalReturnOneYear
                    )
                  : "N/A"}
              </StyledTableCell>
            )}
            {anyAnnualYearThreeData && showYearThree && (
              <StyledTableCell>
                {!isNullOrUndefined(
                  targetPerformanceData?.annualizedTotalReturnThreeYears
                )
                  ? renderPercent(
                      targetPerformanceData?.annualizedTotalReturnThreeYears
                    )
                  : "N/A"}
              </StyledTableCell>
            )}
            {anyYearThreeData && showYearThree && (
              <StyledTableCell>
                {!isNullOrUndefined(
                  targetPerformanceData?.portfolioTotalReturnThreeYears
                )
                  ? renderPercent(
                      targetPerformanceData?.portfolioTotalReturnThreeYears
                    )
                  : "N/A"}
              </StyledTableCell>
            )}
            {anyAnnualYearFiveData && showYearFive && (
              <StyledTableCell>
                {!isNullOrUndefined(
                  targetPerformanceData?.annualizedTotalReturnFiveYears
                )
                  ? renderPercent(
                      targetPerformanceData?.annualizedTotalReturnFiveYears
                    )
                  : "N/A"}
              </StyledTableCell>
            )}
            {anyYearFiveData && showYearFive && (
              <StyledTableCell>
                {!isNullOrUndefined(
                  targetPerformanceData?.portfolioTotalReturnFiveYears
                )
                  ? renderPercent(
                      targetPerformanceData.portfolioTotalReturnFiveYears
                    )
                  : "N/A"}
              </StyledTableCell>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
}

PerformanceTable.propTypes = {
  targetPerformanceData: PropTypes.object,
  currentPerformanceData: PropTypes.object,
  showYearOne: PropTypes.bool,
  showYearThree: PropTypes.bool,
  showYearFive: PropTypes.bool,
};
