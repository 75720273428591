import { all } from 'redux-saga/effects';

import authSaga from './auth.js';
import configSaga from './config.js';
import siteSaga from './site.js';
import integrationsSaga from './integrations.js';
import scrapesSaga from './scrapes.js';

export default function* rootSaga() {
    yield all([
        siteSaga(),
        authSaga(),
        configSaga(),
        integrationsSaga(),
        scrapesSaga(),
    ]);
}
