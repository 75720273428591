import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import PropTypes from "prop-types";

const WarningButton = (props) => {
  const {
    agreeButtonText = "Fortsæt",
    disagreeButtonText = "Fortryd",
    onClick,
    children,
    title,
    description,
  } = props;
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAccept = (e) => {
    setOpen(false);
    onClick(e);
  };
  return (
    <>
      <Button
        sx={{
          "&.MuiButton-root": {
            backgroundColor: "#F44336",
            "&:hover": {
              background: "#f00",
            },
            color: "white",
          },
        }}
        onClick={handleClickOpen}
      >
        {children}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {disagreeButtonText}
          </Button>
          <Button onClick={handleAccept} autoFocus>
            {agreeButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

WarningButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  agreeButtonText: PropTypes.string,
  disagreeButtonText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default WarningButton;
