import React from 'react';
import propTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { renderDate, renderKr } from '../../../utils/renderingService.js';

const AftalerTable = ({ aftaler }) => {
    return (
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Leverandør</TableCell>
                    <TableCell align="right">Aftalenr.</TableCell>
                    <TableCell align="right">Bemærkninger</TableCell>
                    <TableCell align="right">Årlig indbetaling</TableCell>
                    <TableCell align="right">Opsparing</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { aftaler.map((row, index) => (
                    <TableRow key={ `${index}_${row.aftalenr}_${row.leverandoer}` }>
                        <TableCell component="th" scope="row">
                            <strong>{ row.manglerOplysninger ? 'Mangler oplysninger' : row.leverandoer }</strong>
                        </TableCell>
                        <TableCell align="right">
                            <div className="tablePrimary">{ row.aftaleNr } </div> <div className="tableSecondary">{ row.aftaleTypeText }</div>{ ' ' }
                        </TableCell>
                        <TableCell align="right" style={{ whiteSpace: 'pre-line' }}>
                            { row.bemaerkninger }
                        </TableCell>
                        <TableCell align="right">
                            <div className="tablePrimary">{ renderKr(row.indbetaling) }</div> <div className="tableSecondary">{ renderDate(row.indbetalingDato, { numeric: true }) }</div>
                        </TableCell>
                        <TableCell align="right">
                            { row.opsparing ? (
                                <div>
                                    <div className="tablePrimary">{ renderKr(row.opsparing) }</div>
                                    <div className="tableSecondary"> { renderDate(row.opsparingDato, { numeric: true }) }</div>
                                </div>
                            ) : (
                                row.opsparingAlternativText
                            ) }
                        </TableCell>
                    </TableRow>
                )) }
                <TableRow>
                    <TableCell component="th" scope="row">
                        Sum
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right" style={{ whiteSpace: 'pre-line' }}></TableCell>
                    <TableCell align="right">
                        <strong>{ renderKr(aftaler.map(x => x.indbetaling || 0).reduce((sum, x) => sum + x)) }</strong>
                    </TableCell>
                    <TableCell align="right">
                        <strong>{ renderKr(aftaler.map(x => x.opsparing || 0).reduce((sum, x) => sum + x)) }</strong>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

AftalerTable.propTypes = {
    aftaler: propTypes.array.isRequired,
};

export default AftalerTable;
