import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextInputComponent from './TextInputComponent.js';
import { isNullOrWhitespace } from '../../utils/helperFunctions.js';

export default function PhoneNumberInputComponent({ value, onChange, style }) {
    const [error, setError] = useState(false);

    function validateInput(input) {
        if (isNullOrWhitespace(input)) return false;

        if(input.match(/[^\d+]/g)) return false;

        if ((!input.startsWith('+') && input.length === 8 && input.match(/\d/g) && input.match(/\d/g).length === 8)
            || (input.startsWith('+') && input.length === 11 && input.match(/\d/g) && input.match(/\d/g).length === 10)) {
            return true;
        }

        return false;
    }

    return (
        <div style={{ marginTop: 10, display: 'inline-block' }}>
            <TextInputComponent
                error={ error }
                style={ style }
                helperText={ error ? 'Indtast et korrekt telefonnummer' : null }
                name='Telefonnummer'
                id="Telefonnummer"
                label="Telefonnummer"
                textAlign='left'
                value={ value }
                onChange={ x => { onChange(x); setError(!validateInput(x)); } }
            />
        </div>
    );
}

PhoneNumberInputComponent.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object,
};
