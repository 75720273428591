import { omit } from 'lodash';
import { getProcessesDbRef } from './processDataManager.js';

export function updateOnboardingProcess(process) {
    return getProcessesDbRef(process.partnerId, process.customerId)
        .doc(process.processId)
        .set(omit(process, ['partnerId', 'customerId', 'processId', 'isUpdating']));
}

export async function getSingleOnboardingProcess(
    partnerId,
    customerId,
    processId,
) {
    const process = await getProcessesDbRef(partnerId, customerId)
        .doc(processId)
        .get();
    if (process.exists) {
        const processData = process.data();
        return {
            ...processData,
            partnerId,
            customerId,
            processId: process.id,
        };
    }
    return { partnerId, customerId, processId };
}

export async function getAllOnboadringProccess(partnerId, customerId) {
    const processes = await getProcessesDbRef(partnerId, customerId)
        .where('type', '==', 'onboarding')
        .get();
    const mappedData = await Promise.all(
        processes.docs.map(async (p) => {
            return {
                ...p.data(),
                partnerId,
                customerId,
                processId: p.id,
            };
        }),
    );
    return mappedData;
}
