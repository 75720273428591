import { v4 as uuidv4 } from "uuid";
import set from "lodash.set";
import cloneDeep from "lodash.clonedeep";
import * as types from "./actionTypes.js";
import {
  getProcess,
  updateProcess,
  getAllProcesses,
} from "../databaseRepository/processDataManager.js";
import * as investDataManager from "../databaseRepository/investDataManager.js";
import * as mifidDataManager from "../databaseRepository/mifidDataManager.js";

export function getProcessData(customerId, processId) {
  return async (dispatch, getState) => {
    const { auth } = getState();
    dispatch({ type: types.PROCESS_DATA_REQUESTED });
    const processData = await getProcess(
      auth.user.partnerId,
      customerId,
      processId
    );
    return dispatch({ type: types.PROCESS_DATA_LOADED, processData });
  };
}

export function updateProcessData(processData) {
  return async (dispatch) => {
    await updateProcess(processData);
    dispatch({ type: types.PROCESS_DATA_UPDATED, processData });
  };
}

export function updateProcessField(path, value) {
  return async (dispatch, getState) => {
    const { processData } = getState();

    const newProcessData = cloneDeep(processData);
    set(newProcessData, path, value);

    await updateProcess(newProcessData);

    dispatch({ type: types.PROCESS_DATA_UPDATED, processData: newProcessData });
  };
}

export function updateProcessDataField(processId, fieldName, fieldValue) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.PROCESS_DATA_FIELD_UPDATED,
      processId,
      fieldName,
      fieldValue,
    });
    const { processesData } = getState();
    const { processes } = processesData;
    const process = processes.find((x) => x.processId === processId);
    await updateProcess(process);
  };
}

export function createProcess(processData) {
  return async (dispatch, getState) => {
    const { auth, processesData } = getState();

    const processId = uuidv4();
    const now = new Date();
    const newProcessData = {
      partnerId: auth.user.partnerId,
      advisorId: auth.user.advisorId,
      deleted: false,
      createdAt: now,
      processId,
      ...processData,
    };

    await updateProcess(newProcessData);

    dispatch({ type: types.PROCESS_DATA_UPDATED, processData: newProcessData });
    dispatch({
      type: types.PROCESSES_DATA_UPDATED,
      processesData: {
        processes: [...processesData.processes, newProcessData],
      },
    });

    return newProcessData;
  };
}

export function getProcesses(customerId) {
  return async (dispatch, getState) => {
    const { auth } = getState();
    dispatch({ type: types.PROCESSES_DATA_UPDATE_REQUEST });
    const processes = await getAllProcesses(auth.user.partnerId, customerId);
    // eslint-disable-next-line no-restricted-syntax
    for (const process of processes) {
      const status = await getProcessStatus(process); // eslint-disable-line no-await-in-loop
      process.status = status;
    }
    return dispatch({
      type: types.PROCESSES_DATA_UPDATED,
      processesData: { processes },
    });
  };
}

export async function getProcessStatus(process) {
  const statusOption = {
    CREATED: "Oprettet",
    STARTED: "Påbegyndt",
    COMPLETED: "Afsluttet",
  };

  if (process.type === "onboarding") {
    if (process.finished) return statusOption.COMPLETED;
    if (process.started) return statusOption.STARTED;
    return statusOption.CREATED;
  }
  if (process.type === "investCheck") {
    const investData = await investDataManager.getInvestProcess(
      process.partnerId,
      process.customerId,
      process.processId
    );
    return investData.result ? statusOption.COMPLETED : statusOption.CREATED;
  }
  if (process.type === "pensionCheck") {
    const processData = getProcessData(process.customerId, process.processId);
    return processData.files ? statusOption.COMPLETED : statusOption.CREATED;
  }
  if (process.type === "mifidTest") {
    const mifidProcess = await mifidDataManager.getMifidProcess(
      process.partnerId,
      process.customerId,
      process.processId
    );
    if (mifidProcess.mifidData?.finished) return statusOption.COMPLETED;
    if (
      mifidProcess.mifidData?.selectedQuestions?.some(
        (x) => x.answerIndex !== -1
      )
    )
      return statusOption.STARTED;
    return statusOption.CREATED;
  }
  return "Ukendt";
}
