import _ from 'lodash';
import Please from 'pleasejs';

import staticColors from '../colors.js';

export function getNextId(arrayOfObjectsWithIdProp) {
    if (arrayOfObjectsWithIdProp == null) {
        return 0;
    }

    let id = 0;
    for (let i = 0, len = arrayOfObjectsWithIdProp.length; i < len; i += 1) {
        if (arrayOfObjectsWithIdProp[i].id >= id) {
            id = arrayOfObjectsWithIdProp[i].id + 1;
        }
    }
    return id;
}

export function sortOnId(arrayOfObjectsWithIdProp) {
    if (arrayOfObjectsWithIdProp == null) {
        return null;
    }
    return arrayOfObjectsWithIdProp.sort(compareOnId);
}

function compareOnId(a, b) {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 0;
}

export function isNullOrWhitespace( input ) {
    if (input === null || input === undefined) return true;

    return input.replace(/\s/g, '').length < 1;
}

export function isNullOrUndefined(input) {
    return input === null || input === undefined;
}

export function isNullOrNaN(input) {
    return input === null || input === undefined || Number.isNaN(Number(input)) || (typeof input === 'string' && isNullOrWhitespace(input));
}

/**
 * Tries to cast the input to number if it is not null og NaN
 * @param {number} input
 * @returns {*} return Number if not null or NaN
 */
export function toNumber(input) {
    return isNullOrNaN(input) ? null : Number(input);
}

export function objectDifference(...args) {
    function changes(object, base) {
        return _.transform(object, (result, value, key) => {
            if (!_.isEqual(value, base[key])) {
                result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value; // eslint-disable-line no-param-reassign
            }
        });
    }
    return changes(...args);
}

export function additionalColors(colors) {
    const primary = Please.HEX_to_HSV(colors.primary);
    const secondary = Please.HEX_to_HSV(colors.secondary);

    const extras = Please.make_scheme(primary, { scheme_type: 'tri', format: 'hex' }).slice(1);
    const primaryVariations = Please.make_scheme(primary, { scheme_type: 'mono', format: 'hex' });
    const secondaryVariations = Please.make_scheme(secondary, { scheme_type: 'mono', format: 'hex' });

    const primaries = {
        light: primaryVariations[1],
        lighter: primaryVariations[2],
        dark: primaryVariations[3],
        darker: primaryVariations[4],
    };

    const secondaries = {
        light: secondaryVariations[1],
        lighter: secondaryVariations[2],
        dark: secondaryVariations[3],
        darker: secondaryVariations[4],
    };

    return { ...colors, staticColors, primaries, secondaries, extras };
}

export function datesAreOnSameDay(first, second) {
    return first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();
}

export function toDate(input) {
    if (input instanceof Date) return input;
    if (typeof input === 'string') return new Date(input);
    if (typeof input?.toDate !== 'undefined') return input.toDate();
    if (input?._seconds) return new Date(input._seconds * 1000);

    return null;
}

/**
 * @typedef {Object} Asset
 * @property {number} value - The value of the asset
 * @property {string} id - The id of the asset
 */

/**
 * Compares to asset arrays using the id and value of the asset
 * @param {Asset[]} assets - An array of assets
 * @returns {boolean} - Returns true if the arrays are equal
 */
export function compareAssetArrays(arrayOne, arrayTwo) {
    if(arrayOne.length !== arrayTwo.length) return false;

    return arrayOne.every( assetOne => arrayTwo.some(assetTwo => assetTwo.id === assetOne.id && assetTwo.value === assetOne.value));
}

/**
 * Is true if the provided number is not null or nan and larger than zero
 * @param {number} oneTimeManagementCost
 * @returns {boolean}
 */
export function isOneTimeManagmentCostLargerThanZero(oneTimeManagementCost) {
    return !isNullOrNaN(parseFloat(oneTimeManagementCost)) && parseFloat(oneTimeManagementCost) > 0;
}
