import * as types from './actionTypes.js';
import * as investDataManager from '../databaseRepository/investDataManager.js';
import * as investApiManager from '../apiRepository/investApiManager.js';
import * as reportApiManager from '../apiRepository/reportApiManager.js';
import { isNullOrWhitespace } from '../utils/helperFunctions.js';

export function updateInvestData(name, value) {
    return (dispatch, getState) => {
        dispatch({ type: types.INVEST_DATA_UPDATED, name, value });
        const { investData } = getState();
        return investDataManager.updateInvestProcess(investData);
    };
}

/**
 * @typedef {Object} InvestDataFieldUpdate
 * @property {string} fieldName - The name of the field to update
 * @property {any} fieldValue - The value of the field
 */

/**
 * Perform selveral invest data fields updates at once
 * @param {InvestDataFieldUpdate[]} investDataFieldUpdates - An array of assets
 */
export function updateInvestDataBulk(investDataFieldUpdates) {
    return (dispatch, getState) => {
        dispatch({ type: types.INVEST_DATA_BULK_UPDATED, investDataFieldUpdates });
        const { investData } = getState();
        return investDataManager.updateInvestProcess(investData);
    };
}

export function updatePortfolio(portfolio, name, value) {
    return (dispatch, getState) => {
        dispatch({ type: types.INVEST_PORTFOLIO_DATA_UPDATED, portfolio, name, value });
        const { investData } = getState();
        return investDataManager.updateInvestProcess(investData);
    };
}

/**
 * @typedef {Object} PortfolioFieldUpdate
 * @property {string} name - The name of the portfolio
 * @property {string} fieldName - The name of the field to update
 * @property {any} fieldValue - The value of the field
 */

/**
 * Perform selveral portfolio fields updates at once
 * @param {PortfolioFieldUpdate[]} portfolioFieldUpdates - An array of assets
 */
export function updatePortfolioFieldBulk(portfolioFieldUpdates) {
    return (dispatch, getState) => {
        dispatch({ type: types.INVEST_PORTFOLIO_BULK_DATA_UPDATED, portfolioFieldUpdates });
        const { investData } = getState();
        return investDataManager.updateInvestProcess(investData);
    };
}

export function updatePortfolioAssetField(portfolio, index, name, value) {
    return (dispatch, getState) => {
        dispatch({ type: types.INVEST_ASSET_DATA_UPDATED, portfolio, index, name, value });
        const { investData } = getState();
        return investDataManager.updateInvestProcess(investData);
    };
}

/**
 * @typedef {Object} AssetFieldUpdate
 * @property {number} index - The index of the asset
 * @property {string} fieldName - The name of the field to update
 * @property {any} fieldValue - The value of the field
 */

/**
 * Perform selveral asset fields updates at once
 * @param {string} portfolio - The name of the portfolio
 * @param {AssetFieldUpdate[]} assetFieldUpdates - An array of assets
 */
export function updatePortfolioAssetFieldBulk(portfolio, assetFieldUpdates) {
    return (dispatch, getState) => {
        dispatch({ type: types.INVEST_ASSET_BULK_DATA_UPDATED, portfolio, assetFieldUpdates });
        const { investData } = getState();
        return investDataManager.updateInvestProcess(investData);
    };
}

export function updatePortfolioAsset(portfolio, index, asset) {
    return (dispatch, getState) => {
        dispatch({ type: types.INVEST_ASSET_UPDATED, portfolio, index, value: asset });
        const { investData } = getState();
        return investDataManager.updateInvestProcess(investData);
    };
}

export function addPortfolioAsset(portfolio) {
    return (dispatch, getState) => {
        dispatch({ type: types.INVEST_ASSET_ADDED, portfolio });
        const { investData } = getState();
        return investDataManager.updateInvestProcess(investData);
    };
}

export function deletePortfolioAsset(portfolio, index) {
    return (dispatch, getState) => {
        dispatch({ type: types.INVEST_ASSET_DELETED, portfolio, index });
        const { investData } = getState();
        return investDataManager.updateInvestProcess(investData);
    };
}

export function getInvestData(customerId, processId) {
    return async (dispatch, getState) => {
        const { auth } = getState();
        dispatch({ type: types.INVEST_DATA_LOAD_REQUEST });
        const investData = await investDataManager.getInvestProcess(auth.user.partnerId, customerId, processId);
        dispatch({ type: types.INVEST_DATA_LOADED, investData });
    };
}

const fundsTransform = fund => ({
    id: fund.id,
    isin: fund.isin,
    msid: fund.msId,
    value: fund.value,
    stars: fund.msStars,
    calculationSettings: fund.calculationSettings,
});

export function getPortfoliosCosts() {
    return async (dispatch, getState) => {
        const { partnerData, investData } = getState();
        const currentPortfolio = {
            ...investData.currentPortfolio,
            assets: investData.currentPortfolio.assets.filter(asset => (!isNullOrWhitespace(asset.id) || !isNullOrWhitespace(asset.isin)) && !Number.isNaN(asset.value)).map(fundsTransform),
        };
        const targetPortfolio = {
            ...investData.targetPortfolio,
            assets: investData.targetPortfolio.assets.filter(asset => (!isNullOrWhitespace(asset.id) || !isNullOrWhitespace(asset.isin)) && !Number.isNaN(asset.value)).map(fundsTransform),
        };

        dispatch({ type: types.INVEST_CHECK_REQUESTED });
        const calculationDate = new Date();
        const completedTaskArray = await Promise.allSettled([
            investApiManager.getPortfolioPerformance(currentPortfolio, calculationDate),
            investApiManager.getPortfolioPerformance(targetPortfolio, calculationDate),
            investApiManager.getPortfolioAssetAllocation(currentPortfolio, partnerData.customAssets),
            investApiManager.getPortfolioAssetAllocation(targetPortfolio, partnerData.customAssets),
            investApiManager.getPortfolioVolatility(currentPortfolio.assets),
            investApiManager.getPortfolioVolatility(targetPortfolio.assets),
            investApiManager.getPortfolioCost(currentPortfolio, partnerData.customAssets, partnerData.defaultValues.transactionCostIndirect),
            investApiManager.getPortfolioCost(targetPortfolio, partnerData.customAssets, partnerData.defaultValues.transactionCostIndirect),
            investApiManager.getPortfolioMifidCost(currentPortfolio),
            investApiManager.getPortfolioMifidCost(targetPortfolio, investData.oneTimeManagementCost),
            investApiManager.getPortfolioCountryExposure(currentPortfolio),
            investApiManager.getPortfolioCountryExposure(targetPortfolio),
        ]);
        function unwrapCompletedTask(completedTask) {
            if(completedTask.status === 'fulfilled') return completedTask.value.data;

            console.log(completedTask.reason);
            return null;
        }

        const currentPortfolioPerformance = unwrapCompletedTask(completedTaskArray[0]);
        const targetPortfolioPerformance = unwrapCompletedTask(completedTaskArray[1]);
        const currentAllocation = unwrapCompletedTask(completedTaskArray[2]);
        const targetAllocation = unwrapCompletedTask(completedTaskArray[3]);
        const currentPortfolioVolatility = unwrapCompletedTask(completedTaskArray[4]);
        const targetPortfolioVolatility = unwrapCompletedTask(completedTaskArray[5]);
        const currentPortfolioMifidCost = unwrapCompletedTask(completedTaskArray[8]);
        const targetPortfolioMifidCost = unwrapCompletedTask(completedTaskArray[9]);
        const currentPortfolioCountryExposure = unwrapCompletedTask(completedTaskArray[10]);
        const targetPortfolioCountryExposure = unwrapCompletedTask(completedTaskArray[11]);

        if(completedTaskArray[6].status !== 'fulfilled') {
            console.log(completedTaskArray[6].reason);
            dispatch({ type: types.INVEST_CHECK_FAIL, error: completedTaskArray[6].reason });
            return investDataManager.updateInvestProcess(getState().investData);
        }
        if(completedTaskArray[7].status !== 'fulfilled') {
            console.log(completedTaskArray[7].reason);
            dispatch({ type: types.INVEST_CHECK_FAIL, error: completedTaskArray[7].reason });
            return investDataManager.updateInvestProcess(getState().investData);
        }

        const portfolioCostResults = [
            await transformToOldFormat(completedTaskArray[6].value.data.result, currentPortfolio),
            await transformToOldFormat(completedTaskArray[7].value.data.result, targetPortfolio),
        ];

        const results = {
            calculationDate,
            portfolioCostResults,
            currentAllocation,
            targetAllocation,
            currentPortfolioPerformance,
            targetPortfolioPerformance,
            currentPortfolioVolatility,
            targetPortfolioVolatility,
            currentPortfolioMifidCost,
            targetPortfolioMifidCost,
            currentPortfolioCountryExposure,
            targetPortfolioCountryExposure,
        };

        dispatch({ type: types.INVEST_CHECK_SUCCESS, results });

        return investDataManager.updateInvestProcess(getState().investData);
    };
}

const transformToOldFormat = async (portfolioCost, currentPortfolio) => {
    const oldformat = {
        currentPortfolio: [],
        costTable: [],
    };
    oldformat.costTable.push({
        id: 'Plejeomkostninger',
        value: portfolioCost.portfolioManagementYearlyFee.value,
    });

    oldformat.costTable.push({
        id: 'SpreadDepot',
        value: portfolioCost.totalAssetSpreadFees.value,
    });

    oldformat.costTable.push({
        id: 'LobendeOmk',
        value: portfolioCost.totalAssetYearlyFees.value,
    });

    oldformat.costTable.push({
        id: 'SpreadIForeninger',
        value: portfolioCost.totalAssetInternalSpreadFees.value,
    });

    oldformat.costTable.push({
        id: 'Total',
        value: portfolioCost.totalFees.value,
    });

    oldformat.costTable.push({
        id: 'omkostningsandel',
        value: portfolioCost.totalFeesRatio,
    });

    oldformat.currentPortfolio = await Promise.all(currentPortfolio.assets.map(async x => {
        const fundInfo = investApiManager.getFundByIsinAsync(x.isin);
        return { isin: x.isin, name: fundInfo.name, value: x.value, aopact: 0, stars: fundInfo.stars };
    }));
    const sum = oldformat.currentPortfolio.reduce((a, b) => {
        return a + b.value;
    } , 0);

    oldformat.currentPortfolio.push({ isin: 'Total', name: '', value: sum });
    oldformat.currentPortfolio.push({ isin: 'Kontanter', name: 'Kontanter', value: 0, aopact: 0, stars: 0 });
    return oldformat;
};

export function downloadReport(customerId, variation) {
    return async (dispatch, getState) => {
        dispatch({ type: types.REPORT_REQUESTED });
        try {
            const { investData, customerData, partnerData, site, config } = getState();
            const customer = customerData.customers.find((c) => c.customerId === customerId);
            await reportApiManager.downloadReport(variation, investData, customer, partnerData, site, config.features);
            dispatch({ type: types.REPORT_SUCCESS });
            return;
        } catch (error) {
            dispatch({ type: types.REPORT_FAIL, error });
        }
    };
}
