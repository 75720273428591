import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export default function FileNotFoundDialog({ open, onCancel, onConfirm }) {
    const canceled = () => {
        onCancel();
    };

    const confirmed = () => {
        onConfirm();
    };

    return (
        <>
            <Dialog
                open={ open }
            >
                <DialogTitle>Filen blev ikke fundet</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Filen blev ikke fundet, vil du fjerne filen fra oversigten?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ () => canceled() }>
                        Nej
                    </Button>
                    <Button onClick={ () => confirmed() } autoFocus>
                        Ja - fjern filen fra oversigten
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

FileNotFoundDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};
