import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInputComponent from './TextInputComponent.js';

export default function NameInputComponent({ firstName, lastName, onChange, style }) {
    const [internalFirstName, setInternalFirstName] = useState(firstName);
    const [internalLastName, setInternalLastName] = useState(lastName);
    useEffect(() => setInternalFirstName(firstName), [firstName]);
    useEffect(() => setInternalLastName(lastName), [lastName]);

    function localOnChange(localFirstName, localLastName) {
        setInternalFirstName(localFirstName);
        setInternalLastName(localLastName);
        onChange(localFirstName, localLastName);
    }

    return (
        <div style={{ marginTop: 10, display: 'inline-block' }}>
            <TextInputComponent
                style={{ marginRight: 10, ...style }}
                name='Fornavn'
                id="Fornavn"
                label="Fornavn"
                textAlign='left'
                value={ internalFirstName }
                onChange={ value => localOnChange(value, internalLastName) }
            />
            <TextInputComponent
                name='Efternavn'
                id="Efternavn"
                label="Efternavn"
                textAlign='left'
                style={ style }
                value={ internalLastName }
                onChange={ value => localOnChange(internalFirstName, value) }
            />
        </div>
    );
}

NameInputComponent.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object,
};
