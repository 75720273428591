import React from "react";
import PropTypes from "prop-types";
import DefaultHeader from "./MifidDefaultHeader.js";
import { imgToBase64 } from "../../../../utils/base64.js";

export default function DannebroginvestMifidHeader({ alternativeHeader, date, imgData }) {
  return (
    <DefaultHeader alternativeHeader={alternativeHeader} date={date}>
      <img
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          width: "50%",
          height: "auto",
        }}
        alt="Dannebrog Invest logo"
        src={imgData}
      />
    </DefaultHeader>
  );
}

export async function getDannebroginvestHeader(alternativeHeader = false, date) {
  const img = await imgToBase64("/assets/img/dannebroginvest/dannebroginvest-logo.png");
  return (
    <DannebroginvestMifidHeader
      imgData={img}
      alternativeHeader={alternativeHeader}
      date={date}
    />
  );
}

DannebroginvestMifidHeader.propTypes = {
  alternativeHeader: PropTypes.bool,
  date: PropTypes.any,
  imgData: PropTypes.string.isRequired,
};
