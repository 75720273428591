/* eslint-disable */
import React from "react";
import { useSelector } from "react-redux";
import {
  ComposedChart,
  Label,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Area,
} from "recharts";
import { renderKr } from "../../utils/renderingService.js";

const PensionPayoutsGraph = ({ payouts, salary }) => {
  const themeColors = useSelector((state) => state.site.theme.colors);

  const toKr = (value, small) =>
    value &&
    `${renderKr(value, undefined, undefined, undefined, small === true)}`;
  const renderAge = (value) => `${Math.floor(value)} år`;
  const data = salary
    ? [
        {
          age: payouts?.ages[0] - 1,
          salary,
        },
        ...payouts?.ages?.map((age, index) =>
          index === 0
            ? {
                avr: payouts.averagePayout,
                age,
                sum: payouts.sumOfPayouts[index],
                fp:
                  payouts.calculatedFolkepensionPayoutScheme &&
                  payouts.calculatedFolkepensionPayoutScheme.payouts[index],
                salary: 0,
              }
            : {
                avr: payouts.averagePayout,
                age,
                fp:
                  payouts.calculatedFolkepensionPayoutScheme &&
                  payouts.calculatedFolkepensionPayoutScheme.payouts[index],
                sum: payouts.sumOfPayouts[index],
              }
        ),
      ]
    : payouts?.ages?.map((age, index) =>
        index === 0
          ? {
              avr: payouts.averagePayout,
              age,
              sum: payouts.sumOfPayouts[index],
              fp:
                payouts.calculatedFolkepensionPayoutScheme &&
                payouts.calculatedFolkepensionPayoutScheme.payouts[index],
              salary: 0,
            }
          : {
              avr: payouts.averagePayout,
              age,
              sum: payouts.sumOfPayouts[index],
              fp:
                payouts.calculatedFolkepensionPayoutScheme &&
                payouts.calculatedFolkepensionPayoutScheme.payouts[index],
            }
      );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 30,
          right: 30,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid horizontal={false} stroke={"#D3D3D3"} />
        <XAxis
          scale="auto"
          axisLine={false}
          tickLine={false}
          dataKey="age"
          tick={{ dy: 20 }}
          tickFormatter={renderAge}
          minTickGap={20}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickFormatter={toKr}
          tick={{ dx: -20 }}
          width={120}
          type="number"
        />
        <Tooltip formatter={toKr} labelFormatter={renderAge} />
        <Legend
          iconType="plainline"
          iconSize={20}
          wrapperStyle={{ transform: "translate(0, 30px)" }}
          layout="horizontal"
        />
        {salary && (
          <Area
            type="stepAfter"
            dataKey="salary"
            fill="#476789"
            stroke="#476789"
            name="Løn inden pension"
          />
        )}
        <Line
          dot={false}
          type="stepAfter"
          dataKey="sum"
          strokeWidth={3}
          stroke={themeColors.primary}
          name="Udbetaling"
        />
        <Line
          dot={false}
          type="stepAfter"
          dataKey="avr"
          strokeWidth={3}
          stroke={"#989898"}
          strokeDasharray="3 3"
          name="Gennemsnitlig udbetaling"
        />
        {payouts?.calculatedFolkepensionPayoutScheme && (
          <Area
            dot={false}
            type="stepAfter"
            dataKey="fp"
            strokeWidth={3}
            stroke={"#1F62A6"}
            name="Beregnet folkepension"
          />
        )}

        {/* <ReferenceLine y={ payouts.averagePayout } label={ <Label value="Gennemsnitlig udbetaling" position="insideBottomRight" /> } stroke="#D3D3D3" strokeDasharray="3 3" strokeWidth={ 3 } /> */}
        {salary && (
          <ReferenceLine
            x={payouts.ages[0]}
            label={<Label value="Pension" position="insideTop" offset={-20} />}
            stroke={themeColors.secondary}
            strokeWidth={3}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

PensionPayoutsGraph.propTypes = {};

export default PensionPayoutsGraph;
