import React from "react";
import PropTypes from "prop-types";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";

const NavigateBackComponent = (props) => {
  const getPrevRoute = (path) => path.substring(0, path.lastIndexOf("/"));
  const { pathname } = useLocation();
  const prevRoute = getPrevRoute(pathname.replace(/\/$/, ""));
  return (
    <Tooltip title="Tilbage">
      <IconButton
        to={props.skip ? getPrevRoute(prevRoute) : prevRoute}
        component={RouterLink}
        aria-label="tilbage"
      >
        <ArrowBackIcon />
      </IconButton>
    </Tooltip>
  );
};

NavigateBackComponent.propTypes = {
  skip: PropTypes.bool,
};

export default NavigateBackComponent;
