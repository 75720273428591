import React from "react";
import PropTypes from 'prop-types';
import DefaultFooter from './DefaultFooter.js';

export default function ArvadfinanshusFooter({ alternativeFooter = false }) {
    return <DefaultFooter alternativeFooter={ alternativeFooter } footerText='Arvad Finanshus | Emdrupvej 54, 2400 København NV | kontakt@arvad-finanshus.dk | CVR: 36474629'/>;
}

ArvadfinanshusFooter.propTypes = {
    alternativeFooter: PropTypes.bool,
};

