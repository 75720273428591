import React from 'react';
import PropTypes from 'prop-types';
import InputComponent from './InputComponent.js';

export default function TextInputComponent({
    value, name, disabled, onChange, variant, size, inputPropsStyle, label,
    disableBorder, textAlign, multiline, style, fullWidth, placeholder, rows,
    error, helperText }) {
    return (
        <InputComponent
            name={ name }
            value={ value }
            onChange={ x => onChange(x) }
            disabled={ disabled }
            inputPropsStyle={ inputPropsStyle }
            variant={ variant }
            size={ size }
            disableBorder={ disableBorder }
            textAlign={ textAlign }
            label={ label }
            multiline={ multiline }
            style={ style }
            fullWidth={ fullWidth }
            placeholder={ placeholder }
            rows={ rows }
            error={ error }
            helperText={ helperText }
        />
    );
}

TextInputComponent.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    inputPropsStyle: PropTypes.object,
    style: PropTypes.object,
    variant: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.string,
    textAlign: PropTypes.string,
    disableBorder: PropTypes.bool,
    multiline: PropTypes.bool,
    fullWidth: PropTypes.bool,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    error: PropTypes.bool,
    helperText: PropTypes.node,
};
