import React from "react";
import PropTypes from "prop-types";

const ContentHeader = (props) => {
  const { isMobile, maxWidth } = props;

  return (
    <div
      style={{
        padding: isMobile ? "16px 16px 10px 16px" : "0px 0px 10px 0px",
        maxWidth: `${maxWidth}px`,
        height: 68,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        id="section-description"
        style={{
          display: "flex",
        }}
      />
      <div
        id="page-actions"
        style={{
          display: isMobile ? "none" : "flex",
        }}
      />
    </div>
  );
};

ContentHeader.propTypes = {
  isMobile: PropTypes.bool,
  actions: PropTypes.array,
  maxWidth: PropTypes.number,
};

export default ContentHeader;
