import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from '../../UIComponents/index.js';
import UdbetalingerTable from './UdbetalingerTable.js';

const PensionsInfoUdbetalingerView = ({ scrape }) => {
    const [value, setValue] = useState(0);
    const handleChange = newValue => {
        setValue(newValue);
    };
    if (!scrape) return;
    const tabs = scrape.content.udbetalinger.map(udb => ({
        label: `Pensionsalder ${udb.pensionsAlder} år`,
    }));
    const activeUdbetaling = scrape.content.udbetalinger[value];
    return (
        <div>
            <Tabs tabs={ tabs } setValue={ handleChange } value={ value } />
            <UdbetalingerTable udbetalingsRaekker={ activeUdbetaling.udbetalingsRaekker } perioder={ activeUdbetaling.perioder }></UdbetalingerTable>
        </div>
    );
};

PensionsInfoUdbetalingerView.propTypes = {
    scrape: PropTypes.shape({
        content: PropTypes.shape({
            udbetalinger: PropTypes.array.isRequired,
        }),
    }),
};

export default PensionsInfoUdbetalingerView;
