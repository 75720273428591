import React, { lazy, Suspense, useMemo } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { Login, SidebarBottomContent, SidebarTopContent } from './components/index.js';
import { Layout, PageContent } from './components/UIComponents/index.js';
import MifidLink from './components/MifidLink/MifidLink.js';
import OnboardingLink from './components/OnboardingLink/OnboardingLink.js';
import LoadingPage from './components/LoadingPage.js';
import ReportPage from './components/Reports/ReportPage.js';
import MaintenancePage from './components/MaintenancePage.js';

import { paths } from './paths.js';

const LazyRedigerKunde = lazy(() => import('./pages/Kunder/RedigerKunde/index.js'));
const LazyNewayForbindKunde = lazy(() => import('./pages/Kunder/NewayForbindKunde/index.js'));
const LazyOpretKunde = lazy(() => import('./pages/Kunder/OpretKunde/index.js'));
const LazyProfil = lazy(() => import('./pages/Profil/index.js'));
const LazyIndstillinger = lazy(() => import('./pages/Indstillinger/index.js'));
const LazyInvestTjek = lazy(() => import('./pages/Kunder/InvestTjek/index.js'));
const LazyPensionsTjek = lazy(() => import('./pages/Kunder/PensionsTjek/index.js'));
const LazyFileDetails = lazy(() => import('./pages/Kunder/FileDetails/index.js'));
const LazyKunde = lazy(() => import('./pages/Kunder/Kunde/index.js'));
const LazyKunder = lazy(() => import('./pages/Kunder/index.js'));
const LazyOauth = lazy(() => import('./pages/Oauth/index.js'));
const LazyMifid = lazy(() => import('./pages/Kunder/Kunde/Mifid/RiscTest/index.js'));
const LazyOnboarding = lazy(() => import('./pages/Kunder/Kunde/Onboarding/ViewOnboarding.js'));

function Routes() {
    const loggedIn = useSelector(state => state.auth.loggedIn);
    const uid = useSelector(state => state.auth.authUser?.uid ?? '');
    const loading = useSelector(state => state.global?.loading);
    const maintenance = useSelector(state => state.config?.maintenance);
    const location = useLocation();
    const routes = useMemo(() => {
        return (
            <Switch>
                <Layout
                    sidebarBottomContent={ <SidebarBottomContent /> }
                    sidebarTopContent={ <SidebarTopContent /> }
                    logoPath={ `${
                        process.env.PUBLIC_URL
                    }/assets/img/${'default'}/logo-off.svg` }
                    errorCallback={ console.error } // eslint-disable-line no-console
                >
                    <Route exact path="/">
                        <Redirect to="/kunder" />
                    </Route>

                    <Route exact path={ paths.editCustomer.path } component={ WaitingComponent(LazyRedigerKunde) } />
                    <Route exact path={ paths.newayConnectCustomer.path } component={ WaitingComponent(LazyNewayForbindKunde) } />
                    <Route exact path={ paths.createCustomer.path } component={ WaitingComponent(LazyOpretKunde) } />
                    <Route path="/profil" component={ WaitingComponent(LazyProfil) } />
                    <Route path="/oauth" component={ WaitingComponent(LazyOauth) } />
                    <Route
                        path="/indstillinger"
                        component={ WaitingComponent(LazyIndstillinger) }
                    />
                    <Route
                        exact
                        path={ paths.investCheck.path }
                        component={ WaitingComponent(LazyInvestTjek) }
                    />
                    <Route
                        exact
                        path={ paths.mifidTest.path }
                        component={ WaitingComponent(LazyMifid) }
                    />
                    <Route
                        exact
                        path={ paths.onboarding.path }
                        component={ WaitingComponent(LazyOnboarding) }
                    />
                    <Route
                        exact
                        path={ paths.pensionCheck.path }
                        component={ WaitingComponent(LazyPensionsTjek) }
                    />
                    <Route
                        exact
                        path={ paths.fileDetails.path }
                        component={ WaitingComponent(LazyFileDetails) }
                    />
                    <Route
                        exact
                        path={ paths.profile.path }
                        component={ WaitingComponent(LazyKunde) }
                    />
                    <Route exact path="/kunder" component={ WaitingComponent(LazyKunder) } />
                </Layout>
            </Switch>
        );
    }, []);

    if (location.pathname === '/report') return <ReportPage />;
    if (loading && !location.pathname.startsWith('/udfyldMifid') && !location.pathname.startsWith('/onboarding')) return <LoadingPage />;
    if (maintenance) return <MaintenancePage />;
    if (location.pathname.startsWith('/udfyldMifid')) return <MifidLink />;
    if (location.pathname.startsWith('/onboarding')) return <OnboardingLink />;
    if (!loggedIn || uid === 'mifid-user' || uid === 'onboarding-user') return <Login logoPath={ `${process.env.PUBLIC_URL}/assets/img/${'default'}/logo-dark.svg` } />;

    return routes;
}

function WaitingComponent(Component) {
    // eslint-disable-next-line react/display-name
    return (props) => (
        <Suspense
            fallback={ (
                <PageContent>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: 'inherit',
                    }}
                    >
                        <CircularProgress color="secondary" />
                    </div>
                </PageContent>
            ) }
        >
            <Component { ...props } />
        </Suspense>
    );
}

export default Routes;
