import { all, take, put } from 'redux-saga/effects';

import * as types from '../actions/actionTypes.js';
import { getUser, setToken as setTealToken } from '../apiRepository/teal.js';
import { getTealBusinessToken } from '../apiRepository/monaxFunctions.js';

const wait = (ms) => new Promise((res) => setTimeout(res, ms));

function* checkIntegrationStatus() {
    while (true) {
        const action = yield take(types.PARTNER_DATA_UPDATED);
        const partner = action.partnerData;
        if (partner.config?.tealBusiness?.enabled) {
            yield put({ type: types.INTEGRATIONS_LOADING });

            try {
                const tealBusinessToken = yield getTealBusinessToken();
                setTealToken(tealBusinessToken);
                const user = yield getUser();
                const { organisation } = user;
                const ready = !!(organisation?.verified && organisation?.active);
                yield put({ type: types.INTEGRATIONS_UPDATED, payload: { user, ready } });
            } catch (error) {
                yield put({ type: types.INTEGRATIONS_FAILED, payload: { error } });
            }
        } else {
            yield put({ type: types.INTEGRATIONS_RESET });
        }
    }
}

function* refreshIntegrationToken() {
    yield take(types.INTEGRATIONS_UPDATED);
    while (true) {
        yield wait(1000 * 60 * 4); // wait four minutes
        const tealBusinessToken = yield getTealBusinessToken();
        setTealToken(tealBusinessToken);
    }
}

export default function* authSaga() {
    yield all([
        checkIntegrationStatus(),
        refreshIntegrationToken(),
    ]);
}
