import React from 'react';
import PropTypes from 'prop-types';
import PensionFlereOplysningerTable from './PensionFlereOplysningerTable.js';

const PensionsInfoFlereOplysingerView = ({ pensionFlereOplysninger }) => {
    return (
        <div>
            <PensionFlereOplysningerTable pensionFlereOplysninger={ pensionFlereOplysninger }></PensionFlereOplysningerTable>
        </div>
    );
};

PensionsInfoFlereOplysingerView.propTypes = {
    pensionFlereOplysninger: PropTypes.array.isRequired,
};
export default PensionsInfoFlereOplysingerView;
