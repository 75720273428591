import React from "react";
import { useSelector } from "react-redux";
import PropTypes, { array } from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Typography } from "@mui/material";
import { renderPercent } from "../../utils/renderingService.js";
import { isNullOrUndefined } from "../../utils/helperFunctions.js";

function PerformanceBarChart({
  targetPerformanceData,
  currentPerformanceData,
  annually = true,
  total = true,
  showYearOne = true,
  showYearThree = true,
  showYearFive = true,
}) {
  const themeColors = useSelector((state) => state.site.theme.colors);
  const colors = {
    current: themeColors.extras[0],
    suggested: themeColors.primary,
  };

  if (
    (!targetPerformanceData && !currentPerformanceData) ||
    (isNullOrUndefined(targetPerformanceData?.portfolioTotalReturnOneYear) &&
      isNullOrUndefined(currentPerformanceData?.portfolioTotalReturnOneYear)) ||
    !(showYearOne || showYearThree || showYearFive)
  ) {
    return <p>Data ikke tilgængelig!</p>;
  }

  const data = [];

  if (showYearOne)
    data.push({
      name: "1 år",
      target: targetPerformanceData?.portfolioTotalReturnOneYear,
      current: currentPerformanceData?.portfolioTotalReturnOneYear,
    });

  if (
    annually &&
    showYearThree &&
    (!isNullOrUndefined(
      targetPerformanceData?.annualizedTotalReturnThreeYears
    ) ||
      !isNullOrUndefined(
        currentPerformanceData?.annualizedTotalReturnThreeYears
      ))
  ) {
    data.push({
      name: "3 år årligt",
      target: targetPerformanceData?.annualizedTotalReturnThreeYears,
      current: currentPerformanceData?.annualizedTotalReturnThreeYears,
    });
  }

  if (
    total &&
    showYearThree &&
    (!isNullOrUndefined(
      targetPerformanceData?.portfolioTotalReturnThreeYears
    ) ||
      !isNullOrUndefined(
        currentPerformanceData?.portfolioTotalReturnThreeYears
      ))
  ) {
    data.push({
      name: "3 år i alt",
      target: targetPerformanceData?.portfolioTotalReturnThreeYears,
      current: currentPerformanceData?.portfolioTotalReturnThreeYears,
    });
  }

  if (
    annually &&
    showYearFive &&
    (!isNullOrUndefined(
      targetPerformanceData?.annualizedTotalReturnFiveYears
    ) ||
      !isNullOrUndefined(
        currentPerformanceData?.annualizedTotalReturnFiveYears
      ))
  ) {
    data.push({
      name: "5 år årligt",
      target: targetPerformanceData?.annualizedTotalReturnFiveYears,
      current: currentPerformanceData?.annualizedTotalReturnFiveYears,
    });
  }

  if (
    total &&
    showYearFive &&
    (!isNullOrUndefined(targetPerformanceData?.portfolioTotalReturnFiveYears) ||
      !isNullOrUndefined(currentPerformanceData?.portfolioTotalReturnFiveYears))
  ) {
    data.push({
      name: "5 år i alt",
      target: targetPerformanceData?.portfolioTotalReturnFiveYears,
      current: currentPerformanceData?.portfolioTotalReturnFiveYears,
    });
  }

  if (!array.length) {
    return <p>Data ikke tilgængelig!</p>;
  }

  return (
    <>
      {!total && annually && (
        <Typography variant="h5" gutterBottom paragraph>
          Årligt gennemsnitlig afkast
        </Typography>
      )}
      {total && !annually && (
        <Typography variant="h5" gutterBottom paragraph>
          Afkast i alt
        </Typography>
      )}
      <BarChart
        width={500}
        height={400}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis formatter tickFormatter={(value) => renderPercent(value)} />
        <Tooltip formatter={(value) => renderPercent(value)} />
        <Legend />
        {!isNullOrUndefined(
          currentPerformanceData?.portfolioTotalReturnOneYear
        ) && (
          <Bar
            dataKey="current"
            fill={colors.current}
            name="Nuværende portefølje"
          />
        )}
        {!isNullOrUndefined(
          targetPerformanceData?.portfolioTotalReturnOneYear
        ) && (
          <Bar
            dataKey="target"
            fill={colors.suggested}
            name="Foreslået portefølje"
          />
        )}
      </BarChart>
    </>
  );
}

PerformanceBarChart.propTypes = {
  targetPerformanceData: PropTypes.object,
  currentPerformanceData: PropTypes.object,
  annually: PropTypes.bool,
  total: PropTypes.bool,
  showYearOne: PropTypes.bool,
  showYearThree: PropTypes.bool,
  showYearFive: PropTypes.bool,
};

export default PerformanceBarChart;
