import cryptoRandomString from 'crypto-random-string';
import { db } from './database.js';

function magicLinkRef() {
    return db.collection('magicLinkTokens');
}

export async function getMagicLinkToken(token) {
    const magicToken = await magicLinkRef().doc(token).get();
    if(magicToken.exists) return { ...magicToken.data() };
    return null;
}

export async function generateAndAddMagicLinkToken(partnerId, customerId, processId, uid) {
    const magicToken = cryptoRandomString({ length: 64, type: 'url-safe' });
    const currentDate = new Date();
    const expirationDate = currentDate.setDate(currentDate.getDate() + 30);
    const token = { magicToken, expirationDate, partnerId, customerId, processId, uid };
    await magicLinkRef().doc(magicToken).set(token);
    return token;
}

export function deleteMagicLinkToken(magicTokenId){
    return magicLinkRef().doc(magicTokenId).delete();
}
