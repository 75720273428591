import React, { useState } from 'react';
import { detect } from 'detect-browser';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Report as ReportIcon } from '@mui/icons-material';

export default function BrowserSupportModal() {
    const browser = detect();
    const supported = browser.name !== 'chrome';
    const [modalOpen, setModalOpen] = useState(supported);

    if ([ 'local', 'local-dev' ].includes(process.env.REACT_APP_ENV)) return null;// if running local disable browser check

    return (
        <Dialog
            open={ modalOpen }
            onClose={ () => setModalOpen(false) }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <ReportIcon style={{ verticalAlign: 'text-top' }}/>Din browser er ikke understøttet
            </DialogTitle>
            <DialogContent>
                <div>
                    <p>
                        Monax virker lige nu best på Google Chrome, og du kan opleve diverse fejl ved brug af andre browsere.
                    </p>
                    <p>
                        Du kan hente den nyeste version af Chrome på&nbsp;<a
                            href="https://www.google.com/chrome/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >https://www.google.com/chrome/
                        </a>.
                    </p>
                    <p>Vi beklager ulejligheden.</p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={ () => setModalOpen(false) }>
                    Fortsæt
                </Button>
            </DialogActions>
        </Dialog>
    );
}
