import React from "react";
import PropTypes from "prop-types";
import { Button, TextField, Card } from "@mui/material";

const texts = {
  "User not found": "Forkert kodeord",
  "Too many requests": "For mange forsøg - prøv igen senere",
};

const LoginForm = (props) => {
  const { error } = props;
  return (
    <Card>
      <form
        onSubmit={props.login}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "340",
          padding: 40,
        }}
      >
        <div
          style={{
            fontSize: 15,
            color: "#032d40",
            marginBottom: 12,
          }}
        >
          Velkommen tilbage
        </div>
        <TextField
          value={props.email}
          onChange={(e) => props.setEmail(e.target.value)}
          color="secondary"
          autoFocus
          label="Email"
          required
          type="email"
          autoComplete="email"
          style={{ marginBottom: 31 }}
        />
        <TextField
          value={props.password}
          onChange={(e) => props.setPassword(e.target.value)}
          color="secondary"
          required
          label="Kodeord"
          type="password"
          autoComplete="current-password"
          error={!!error}
          helperText={texts[error?.message]}
        />
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="text"
            color="secondary"
            size="small"
            onClick={() => props.setShowForgotPassword(true)}
          >
            Glemt kodeord?
          </Button>
          <Button type="submit" disabled={props.loading}>
            Log ind
          </Button>
        </div>
      </form>
    </Card>
  );
};

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  setShowForgotPassword: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool,
};

export default LoginForm;
