import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material";
import PageActions from "../PageActions/index.js";

const TopActions = (props) => {
  const ButtonContainer = styled("div")(() => ({
    "& > *": {
      margin: "8px !important",
    },
    "& > *:last-child": {
      marginRight: "0px !important",
    },
  }));
  return (
    <PageActions id="page-actions-top">
      <ButtonContainer>{props.children}</ButtonContainer>
    </PageActions>
  );
};

TopActions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default TopActions;
