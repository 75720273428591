import { eventChannel } from 'redux-saga';
import { all, call, put, take } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import { auth as authentication } from '../databaseRepository/database.js';
import * as types from '../actions/actionTypes.js';
import { getUser } from '../databaseRepository/userDataManager.js';
import { getAllCustomers } from '../databaseRepository/customerDataManager.js';
import { getPartner, updatePartner } from '../databaseRepository/partnerDataManager.js';
import { getAdvisor, getAllAdvisors } from '../databaseRepository/advisorDataManager.js';
import { isNullOrWhitespace } from '../utils/helperFunctions.js';
import defaultOnboardingQuestionnaires, { defaultQuestionnairVersionNumber } from '../reducers/defaultOnboardingQuestionnaires.js';

function* waitForStateChange() {
    const channel = eventChannel(emitter => authentication.onAuthStateChanged((authUser => emitter({ authUser }))));

    while (true) {
        const { authUser } = yield take(channel);
        yield call(stateChanged, authUser);
    }
}

function* stateChanged(authUser) {
    if(!authUser) return yield put({ type: types.AUTH_NOT_LOGGED_IN });

    yield authentication?.currentUser?.getIdToken(true); // force token refresh

    if(authUser?.uid === 'mifid-user' || authUser?.uid === 'onboarding-user') {
        const decodedToken = jwtDecode(authUser.multiFactor.user.stsTokenManager.accessToken);
        const partnerId = isNullOrWhitespace(decodedToken['https://monax.dk/orgId']) ? 'ml-advice' : decodedToken['https://monax.dk/orgId'];

        yield put({ type: types.PARTNER_DATA_REQUESTED });
        const partnerData = yield getPartner(partnerId);
        partnerData.loaded = true;
        yield put({ type: types.PARTNER_DATA_UPDATED, partnerData: { name: partnerData.name, partnerId: partnerData.partnerId } });

        yield put({
            type: types.AUTH_DATA_LOADED,
            authUser: { uid: authUser.uid },
            user: {
                partnerId,
            },
        });
        return;
    }

    const user = yield getUser(authUser.uid);

    if (!user?.advisorId) return alert(`Login with non-advisor profile not supported.\nUserID: ${authUser.uid}`); // eslint-disable-line no-alert

    const advisor = yield getAdvisor(user.partnerId, user.advisorId);

    yield put({
        type: types.AUTH_DATA_LOADED,
        authUser: { email: authUser.email, uid: authUser.uid },
        user,
        advisor,
    });

    yield put({
        type: types.ADVISOR_DATA_ADDED,
        advisorId: user.advisorId,
        advisorData: advisor,
    });

    yield put({
        type: types.ADVISOR_DATA_SET_CURRENT,
        advisorId: user.advisorId,
    });

    yield put({ type: types.PARTNER_DATA_REQUESTED });
    const partnerData = yield getPartner(user.partnerId);
    partnerData.loaded = true;
    if(partnerData?.onboarding?.questionnaireVersion !== defaultQuestionnairVersionNumber) {
        yield put({ type: types.PARTNER_DATA_UPDATED, partnerData: {
            ...partnerData,
            onboarding: {
                questionnaireVersion: defaultQuestionnairVersionNumber,
                questionnaires: defaultOnboardingQuestionnaires,
            },
        } } );
        yield updatePartner({
            ...partnerData,
            onboarding: {
                questionnaireVersion: defaultQuestionnairVersionNumber,
                questionnaires: defaultOnboardingQuestionnaires,
            },
        });
    } else {
        yield put({ type: types.PARTNER_DATA_UPDATED, partnerData });
    }

    const advisors = yield getAllAdvisors(user.partnerId);
    yield put({
        type: types.ADVISOR_DATA_MULTIPLE_ADDED,
        advisorData: advisors,
    });

    yield put({ type: types.CUSTOMER_DATA_UPDATE_REQUEST });
    const customers = yield getAllCustomers(user.partnerId);
    yield put({ type: types.CUSTOMER_ALL_DATA_UPDATED, customers });

};

function* advisorChanged() {
    while (true) {
        const { advisorData } = yield take('ADVISOR_DATA_UPDATED');
        yield put({
            type: types.AUTH_DATA_LOADED,
            advisor: advisorData,
        });
    }
}

export default function* authSaga() {
    yield all([
        waitForStateChange(),
        advisorChanged(),
    ]);
}
