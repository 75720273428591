import { toDate } from "./helperFunctions.js";

export const renderKr = (
  x,
  maximumDigits = 0,
  minimumDigits = 0,
  withLabel = true
) => {
  if (x === undefined) {
    return 0;
  }

  return `${x.toLocaleString("da", {
    minimumFractionDigits: minimumDigits,
    maximumFractionDigits: maximumDigits,
  })} ${withLabel ? "kr." : ""}`;
};

export const renderDecimal = (x, decimals = 0) => {
  if (x === undefined) {
    return 0;
  }
  return Math.round(x, decimals);
};

export function renderPercent(x, maximumDigits = 2, minimumDigits = 0) {
  if (x === undefined) {
    return "0%";
  }
  return `${(x * 100).toLocaleString("da", {
    minimumFractionDigits: minimumDigits,
    maximumFractionDigits: maximumDigits,
  })}%`;
}

export function renderNumber(
  x,
  pct = false,
  maximumDigits = 0,
  minimumDigits = 0
) {
  if (x === undefined) {
    return 0;
  }
  if (pct === false) {
    return `${x.toLocaleString("da", {
      minimumFractionDigits: minimumDigits,
      maximumFractionDigits: maximumDigits,
    })} kr.`;
  }
  return `${x.toLocaleString("da", {
    minimumFractionDigits: minimumDigits,
    maximumFractionDigits: maximumDigits,
  })} %`;
}

export function renderCpr(str) {
  if (!str) {
    return "";
  }
  return `${str.slice(0, 6)}-${str.slice(6, 10)}`;
}

export const defaultTurnover = 1 / 7;

export function getExcessTurnoverRatio(excessTurnoverYears) {
  const excessTurnoverRatio = 1 / Number(excessTurnoverYears) - defaultTurnover;
  return excessTurnoverRatio;
}

export function getExcessTurnoverYears(excessTurnoverRatio) {
  const excessTurnoverYears =
    1 / (Number(excessTurnoverRatio) + defaultTurnover);
  return excessTurnoverYears;
}

export function renderExcessTurnoverYears(excessTurnoverYears) {
  return `${Math.round(excessTurnoverYears * 100) / 100} år`;
}

export function renderDate(date, { numeric } = {}) {
  if (!date) return "";
  const d = toDate(date);

  return d.toLocaleString("da-DK", {
    weekday: numeric ? undefined : "long",
    year: "numeric",
    month: numeric ? "numeric" : "long",
    day: "numeric",
  });
}

export function renderDateTime() {}
export const renderShort = (title) => {
  const regExp = /\(([^)]+)\)/;
  const matches = regExp.exec(title);
  if (matches && !matches[1].includes("tidligere")) {
    return matches[1];
  }
  return title;
};

export function getFormatedStringFromDays(numberOfDays) {
  const years = Math.floor(numberOfDays / 365);
  const months = Math.floor((numberOfDays % 365) / 30);
  const days = Math.floor((numberOfDays % 365) % 30);

  const yearsDisplay = years > 0 ? `${years} år og ` : "";
  const monthsDisplay = months + (months === 1 ? " måned " : " måneder ");
  const daysDisplay =
    days > 0 ? ` og ${days}${days === 1 ? " dag" : " dage"}` : "";
  return (
    yearsDisplay + monthsDisplay + (yearsDisplay === "" ? daysDisplay : "")
  );
}
