import { omit } from "lodash";
import { db } from "./database.js";

export function getProcessesDbRef(partnerId, customerId) {
  return db.collection(
    `partners/${partnerId}/customers/${customerId}/processes`
  );
}

export async function getProcess(partnerId, customerId, processId) {
  const process = await getProcessesDbRef(partnerId, customerId)
    .doc(processId)
    .get();
  if (process.exists) {
    return { ...process.data(), partnerId, customerId, processId };
  }
  throw new Error(
    `Process with partnerId ${partnerId}, customerId ${customerId} and processId ${processId} not found`
  );
}

export async function getAllProcesses(partnerId, customerId) {
  const processes = await getProcessesDbRef(partnerId, customerId).get();
  return processes.docs.map((p) => ({
    ...p.data(),
    partnerId,
    customerId,
    processId: p.id,
  }));
}

export function updateProcess(process) {
  return getProcessesDbRef(process.partnerId, process.customerId)
    .doc(process.processId)
    .set(omit(process, ["partnerId", "customerId", "processId"]));
}
