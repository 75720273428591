import React from "react";
import PropTypes from "prop-types";

class DefaultPageTemplate extends React.Component {
  render() {
    const { children, alternativeFooter = false } = this.props;
    return (
      <div
        className="page A4-landscape"
        style={{ display: "flex", flexDirection: "column", padding: "10px" }}
      >
        <div style={{ height: "40px" }} />
        <div style={{ flexGrow: 1, margin: "10px 20px" }}>{children}</div>
        {!alternativeFooter ? (
          <div
            style={{ height: "40px", textAlign: "center", color: "#999999" }}
          >
            Missing Link ApS | Studio Nordvest Glasvej 3, 1., 2400 København NV
            | CVR: 40297979
          </div>
        ) : (
          <div style={{ height: "40px", textAlign: "center" }}>
            Missing Link ApS | Studio Nordvest Glasvej 3, 1., 2400 København NV
            | CVR: 40297979
          </div>
        )}
      </div>
    );
  }
}

DefaultPageTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  alternativeFooter: PropTypes.bool,
};

export default DefaultPageTemplate;
