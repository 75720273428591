import { omit } from 'lodash';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { db } from './database.js';

function partnerRef(partnerId) {
    return db.collection('partners').doc(partnerId);
}

export async function getPartner(partnerId) {
    const partner = await partnerRef(partnerId).get();
    if (partner.exists) return { ...partner.data(), partnerId };
    throw new Error(`Partner with partnerId ${partnerId} not found`);
}

const updatedPartnerDataDebounced = AwesomeDebouncePromise(partner => partnerRef(partner.partnerId).set(omit(partner, ['partnerId'])), 100);

export async function updatePartner(partner) {
    updatedPartnerDataDebounced(partner);
}
