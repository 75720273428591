import initialState from './initialState.js';
import * as types from '../actions/actionTypes.js';

import { additionalColors } from '../utils/helperFunctions.js';

export default function siteReducer(state = initialState.site, action) {
    switch (action.type) {
        case types.SITE_LOADING:
        {
            return {
                ...state,
                globalLoading: true,
            };
        }
        case types.SITE_CHANGED:
        {
            const colors = {
                ...initialState.site.theme.colors,
                ...action.payload?.theme?.colors,
            };
            return {
                ...initialState.site,
                data: {
                    ...initialState.site.data,
                    ...action.payload?.data,
                },
                theme: {
                    ...initialState.site.theme,
                    ...action.payload?.theme,
                    colors: additionalColors(colors),
                },
                globalLoading: false,
            };
        }
        default:
            return state;
    }
}
