import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, Autocomplete } from "@mui/material";

export default function AutocompleteAsync({
  fieldLabel,
  onChange,
  placeholder = "Skriv for at søge..",
  value,
  getOptions,
  searchField,
  alternativeSearchField = null,
  disabled = false,
}) {
  const [internalValue, setInternalValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = React.useState(false);
  const loading = open && options.length === 0;

  useEffect(() => {
    setInternalValue(!value?.id || value.id === "" ? null : value);
  }, [searchField, value]);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    async function fetchOptions() {
      const result = await getOptions();
      const mappedResult = result.map((f) => ({
        id: f.id,
        name: f.name,
        isin: f.isin,
      }));
      if (active) setOptions(mappedResult);
    }
    fetchOptions();
    return () => {
      active = false;
    };
  }, [getOptions, loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  function internalOnChange(newValue) {
    setOptions(newValue ? [newValue, ...options] : []);
    setInternalValue(newValue);
    onChange(newValue);
  }

  return (
    <Autocomplete
      id="AutocompleteAsync"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option[searchField] ?? ""
      }
      isOptionEqualToValue={(option, x) => option?.id === x?.id}
      filterOptions={(x, y) => {
        return searchFunds(
          x,
          searchField,
          y.inputValue,
          alternativeSearchField
        );
      }}
      clearOnBlur
      disabled={disabled}
      options={options}
      onKeyDown={(e) => e.key === "Enter" && document.activeElement.blur()}
      autoComplete
      includeInputInList
      noOptionsText={placeholder}
      value={internalValue}
      onChange={(event, newValue) => internalOnChange(newValue)}
      renderInput={(params) => (
        <TextField {...params} label={fieldLabel} variant="standard" />
      )}
    />
  );
}

function searchFunds(localOptions, field, searchString, alternativeField) {
  let funds = searchFundsInternal(localOptions, field, searchString);
  if (funds.length === 0 && alternativeField) {
    funds = searchFundsInternal(localOptions, alternativeField, searchString);
  }

  return funds;
}

function searchFundsInternal(localOptions, field, searchString) {
  const strings = searchString.split(" ");
  let funds = localOptions;
  strings.forEach((string) => {
    funds = funds.filter((i) => {
      if (!i[field]) return false;

      if (string === "og" || string === "&")
        return (
          i[field].toLowerCase().includes("og") ||
          i[field].toLowerCase().includes("&")
        );

      return i[field].toLowerCase().includes(string.toLowerCase());
    });
  });
  return funds;
}

AutocompleteAsync.propTypes = {
  onChange: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  value: PropTypes.object,
  fieldLabel: PropTypes.string.isRequired,
  searchField: PropTypes.string.isRequired,
  alternativeSearchField: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};
