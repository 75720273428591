import React from 'react';
import { CircularProgress } from '@mui/material';

function LoadingPage() {
    return (
        <div style={{ height: 'calc(100% - 80px)' }}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height:'100%',
            }}>
                <h1>Maintenance happening -- we will be back shortly!</h1>
                <CircularProgress color="secondary" />
            </div>
        </div>
    );
}

export default LoadingPage;
