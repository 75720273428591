import { all, put, take, select } from 'redux-saga/effects';
import { db } from '../databaseRepository/database.js';
import { getSite } from '../databaseRepository/siteManager.js';
import * as types from '../actions/actionTypes.js';

function* loadThemeOnStartup() {
    yield put({ type: types.SITE_LOADING });

    yield take(types.AUTH_NOT_LOGGED_IN);
    const site = yield getSiteByHost();
    yield put({ type: types.SITE_CHANGED, payload: site });
}

function* loadThemeOnLogin() {
    while (true) {
        yield take(types.AUTH_DATA_LOADED);
        const { user } = yield select(state => state.auth);

        const site = yield getSite(user.partnerId);
        yield put({ type: types.SITE_CHANGED, payload: site });
    }
}

function* loadThemeOnSignout() {
    while (true) {
        yield take(types.AUTH_SIGNOUT);
        const site = yield getSiteByHost();
        yield put({ type: types.SITE_CHANGED, payload: site });
    }
}

async function getSiteByHost() {
    const siteRefernece = await db.collection('sites').doc(`${window.location.host}`).get();
    if (siteRefernece.exists) {
        const { partnerId } = await siteRefernece.data();
        const siteDocuments = await db.collection(`partners/${partnerId}/site`).get();

        const site = { };
        siteDocuments.docs.forEach(doc => {
            site[doc.id] = doc.data();
        });
        return site;
    }
}

export default function* siteSaga() {
    yield all([
        loadThemeOnStartup(),
        loadThemeOnLogin(),
        loadThemeOnSignout(),
    ]);
}
