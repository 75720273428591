import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import customerData from './customerDataReducer.js';
import partnerData from './partnerDataReducer.js';
import wealthData from './wealthDataReducer.js';
import documentData from './documentDataReducer.js';
import providerData from './providerDataReducer.js';
import aaopData from './aaopDataReducer.js';
import adviceModuleData from './adviceModuleDataReducer.js';
import processData from './processDataReducer.js';
import processesData from './processesDataReducer.js';
import advisorData from './advisorDataReducer.js';
import auth from './authReducer.js';
import config from './configReducer.js';
import site from './siteReducer.js';
import integrations from './integrations.js';
import investData from './investDataReducer.js';
import mifidProcessesData from './mifidProcessesReducer.js';
import onboardingProcessesData from './onboardingProcessesReducer.js';
import projection from './projectionReducer.js';
import eventsData from './eventsDataReducer.js';
import uniify from './uniifyReducer.js';
import initialState from './initialState.js';

const appReducer = combineReducers({
    partnerData,
    wealthData,
    customerData,
    documentData,
    providerData,
    adviceModuleData,
    advisorData,
    aaopData,
    processData,
    investData,
    mifidProcessesData,
    onboardingProcessesData,
    processesData,
    projection,
    uniify,
    auth,
    config,
    site,
    integrations,
    eventsData,
    routing: routerReducer,
    global: state => ({ ...state }),
});

const rootReducer = (state, action) => {
    if (action.type === 'AUTH_SIGNOUT') return initialState;

    const newState = appReducer(state, action);
    const loading = Object.keys(newState).some(key => newState[key].globalLoading);
    return { ...newState, global: { loading } };
};


export default rootReducer;
