import React from "react";
import PropTypes from "prop-types";
import { styled, Typography } from "@mui/material";

const Content = styled("div")(({ theme }) => ({
  padding: "32",
  maxWidth: "350",
  borderRadius: "4",
  border: `1px solid ${theme.palette.grey[100]}`,
  background: "white",
  "@media (max-width: 400px)": {
    maxWidth: "100%",
    borderRadius: 0,
    border: "none",
  },
}));

const Logo = styled("img")(() => ({
  marginTop: 16,
  maxWidth: 350,
  "@media (max-width: 350px)": {
    maxWidth: 250,
  },
}));

const Link = styled("a")(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <Content>
            <Typography variant="h4" gutterBottom>
              Øv, der gik noget galt...
            </Typography>
            <Typography variant="body2">
              Vi har har noteret fejlen, og vil arbejde på at udbedre den
              hurtigst muligt.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Kontakt os gerne, og hjælp os ved at beskrive hvordan fejlen
              opstod.
            </Typography>
            <Typography variant="body2">
              Vi beklager meget, og vi håber at du kan fortsætte{" "}
              <Link href={window.origin}> her</Link>.
            </Typography>
            <Logo
              src={
                this.props.imgPath ||
                `${process.env.PUBLIC_URL}/assets/img/bug-fix.svg`
              }
            />
          </Content>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  errorCallback: PropTypes.func,
  imgPath: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export default ErrorBoundary;
