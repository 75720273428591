import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import toastr from "toastr";

import { Button } from "@mui/material";
import LoginForm from "./LoginForm/index.js";
import ForgotPasswordForm from "./ForgotPasswordForm/index.js";
import * as authActions from "../../actions/authActions.js";
import { config } from "../../config/index.js";
import useSearchParams from "../../hooks/useSearchParams.js";
import { getClient } from "../../apiRepository/monaxFunctions.js";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState();
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const onLogIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    const error = await props.actions.loginWithEmailAndPassword(
      email,
      password
    );
    if (error) {
      setLoginError(error);
      setPassword("");
    }
    setLoading(false);
  };

  const [client, setClient] = useState();

  const params = useSearchParams();
  const clientId = params?.client_id; // eslint-disable-line camelcase

  useEffect(() => {
    if (!clientId) return;
    const fetch = async () => setClient(await getClient(clientId));
    fetch();
  }, [clientId]);

  const onResetPasswordRequest = (e) => {
    e.preventDefault();
    props.actions.resetPassword(email);
    toastr.options = { positionClass: "toast-top-center" };
    toastr.success("Nulstilling sendt til din email");
    setShowForgotPassword(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          position: "absolute",
          left: "30%",
          background: "url(assets/img/login.png)",
          WebkitBackgroundSize: "cover",
          MozBackgroundSize: "cover",
          OBackgroundSize: "cover",
          backgroundSize: "cover",
          width: "100%",
          height: "100%",
        }}
      />
      <img
        src={props.logoPath}
        style={{
          position: "absolute",
          top: 40,
          left: 40,
          zIndex: 1,
          width: 105,
        }}
      />
      {config.env !== "prod" && (
        <div
          style={{
            position: "absolute",
            bottom: 40,
            left: 40,
            zIndex: 1,
          }}
        >
          <p style={{ margin: 0, fontFamily: "Roboto", fontSize: "14px" }}>
            <b>{config.env} build</b>
          </p>
          {config.version && (
            <p style={{ margin: 0, fontFamily: "Roboto", fontSize: "14px" }}>
              <span>version {config.version}</span>
            </p>
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          background: "#F4F6F8",
          boxShadow: "5px 0px 15px rgba(0, 0, 0, 0.1)",
          width: "50%",
          minWidth: 380,
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {client && (
          <div>
            Log ind for at fortsætte med login til{" "}
            <strong>{client.name}</strong>
          </div>
        )}

        {!showForgotPassword && (
          <LoginForm
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            login={onLogIn}
            setShowForgotPassword={setShowForgotPassword}
            error={loginError}
            loading={loading}
          />
        )}
        {showForgotPassword && (
          <ForgotPasswordForm
            email={email}
            setEmail={setEmail}
            onResetPasswordRequest={onResetPasswordRequest}
          />
        )}
        <div
          style={{
            position: "absolute",
            bottom: 36,
          }}
        >
          <Button
            variant="text"
            color="secondary"
            size="small"
            href="mailto:support@monax.dk"
          >
            Kontakt os
          </Button>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  actions: PropTypes.object.isRequired,
  logoPath: PropTypes.any,
};
function mapStateToProps(state) {
  return {
    authData: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...authActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
