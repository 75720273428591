import React from "react";
import PropTypes from "prop-types";
import DefaultHeader from "./MifidDefaultHeader.js";
import { imgToBase64 } from "../../../../utils/base64.js";

export default function UvildigraadMifidHeader({
  alternativeHeader = false,
  date,
  imgData,
}) {
  return (
    <DefaultHeader alternativeHeader={alternativeHeader} date={date}>
      <img
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          width: "50%",
          height: "auto",
        }}
        alt="Uvildigraad logo"
        src={imgData}
      />
    </DefaultHeader>
  );
}

export async function getUvildigraadMifidHeader(alternativeHeader, date) {
  const img = await imgToBase64("/assets/img/uvildigraad/logo.png");
  return (
    <UvildigraadMifidHeader
      imgData={img}
      alternativeHeader={alternativeHeader}
      date={date}
    />
  );
}

UvildigraadMifidHeader.propTypes = {
  alternativeHeader: PropTypes.bool,
  date: PropTypes.any,
  imgData: PropTypes.string.isRequired,
};
