import React, { useState } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem, Button } from "@mui/material";

import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

export default function Dropdown({ menuText, options, style = {} }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        variant="contained"
        style={{ ...style }}
        aria-controls="simple-menu"
        onClick={handleClick}
      >
        <ExpandMoreIcon />
        {menuText}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                option.action();
                handleClose();
              }}
            >
              {option.text}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

Dropdown.propTypes = {
  menuText: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    })
  ).isRequired,
  style: PropTypes.object,
};
