import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextInputComponent from './TextInputComponent.js';
import { isNullOrWhitespace } from '../../utils/helperFunctions.js';

export default function EmailInputComponent({ value, onChange, style }) {
    const [error, setError] = useState(false);

    function validateInput(input) {
        if (isNullOrWhitespace(input)) return false;
        if (input.toString().indexOf('@') === -1 || input.toString().indexOf('.') === -1) return false;

        return true;
    }

    return (
        <div style={{ marginTop: 10, maxWidth: 310,  display: 'inline-block' }}>
            <TextInputComponent
                error={ error }
                helperText={ error ? 'Indtast en korrekt e-mail' : null }
                name='Email'
                id="Email"
                label="Email"
                textAlign='left'
                fullWidth
                style={ style }
                value={ value }
                onChange={ x => { onChange(x); setError(!validateInput(x)); } }
            />
        </div>
    );
}

EmailInputComponent.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.object,
};
