import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material";
import PageActions from "../PageActions/index.js";
import NavigateBackComponent from "../NavigateBackComponent/index.js";

const SectionDescription = (props) => {
  const { isRoot = false, skip = false } = props;
  const theme = useTheme();
  return (
    <PageActions id="section-description">
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: isRoot ? "none" : "flex",
            alignItems: "center",
            marginRight: "12px",
          }}
        >
          <NavigateBackComponent skip={skip} />
        </div>
        <div
          style={{
            fontSize: "20px",
            color: theme.palette.secondary.main,
            lineHeight: "15px",
          }}
        >
          {props.children}
        </div>
      </div>
    </PageActions>
  );
};

SectionDescription.propTypes = {
  isRoot: PropTypes.bool,
  skip: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SectionDescription;
