import initialState from './initialState.js';
import * as types from '../actions/actionTypes.js';

import { getAssetsSum, getCostComparison } from '../utils/calc.js';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.

const arrayToObject = (array, keyField, valueField = 'Vaerdi') =>
    array.reduce((obj, item) => {
        if (typeof item[valueField] !== 'undefined') {
            obj[item[keyField]] = { value: item[valueField], text: item[keyField] }; // eslint-disable-line no-param-reassign
        }
        return obj;
    }, { });

export default function investDataReducer(state = initialState.investData, action) {
    if (action.type === types.INVEST_ASSET_DATA_UPDATED) {
        const portfolio = state[action.portfolio];
        const { assets } = portfolio;
        const asset = assets[action.index];
        return {
            ...state,
            isFetching: false,
            [action.portfolio]: {
                ...portfolio,
                assets: [
                    ...assets.slice(0, action.index),
                    { ...asset, [action.name]: action.value },
                    ...assets.slice(action.index + 1),
                ],
            },
        };
    }

    if (action.type === types.INVEST_ASSET_BULK_DATA_UPDATED) {
        const portfolio = state[action.portfolio];
        const { assets } = portfolio;

        let updatedAssets = [ ...assets ];
        for (let index = 0; index < action.assetFieldUpdates.length; index += 1) {
            const assetFieldUpdate = action.assetFieldUpdates[index];
            const asset = updatedAssets[assetFieldUpdate.index];
            updatedAssets = [
                ...updatedAssets.slice(0, assetFieldUpdate.index),
                { ...asset, [assetFieldUpdate.fieldName]: assetFieldUpdate.fieldValue },
                ...updatedAssets.slice(assetFieldUpdate.index + 1),
            ];
        }

        return {
            ...state,
            isFetching: false,
            [action.portfolio]: {
                ...portfolio,
                assets: [ ...updatedAssets ],
            },
        };
    }

    if (action.type === types.INVEST_ASSET_UPDATED) {
        const portfolio = state[action.portfolio];
        const { assets } = portfolio;
        const asset = assets[action.index];
        return {
            ...state,
            isFetching: false,
            [action.portfolio]: {
                ...portfolio,
                assets: [
                    ...assets.slice(0, action.index),
                    {
                        ...asset,
                        id: action.value.id,
                        name: action.value.name,
                        isin: action.value.isin,
                        msStars: action.value.msStars,
                        msStarsDate: action.value.msStarsDate,
                        calculationSettings: action.value.calculationSettings,
                        firstPriceDate: action.value.firstPriceDate,
                    },
                    ...assets.slice(action.index + 1),
                ],
            },
        };
    }

    if (action.type === types.INVEST_ASSET_ADDED) {
        const portfolio = state[action.portfolio];
        const { assets } = portfolio;
        return {
            ...state,
            isFetching: false,
            [action.portfolio]: {
                ...portfolio,
                assets: [...assets, { name: '', isin: '', value: 0, msStars: 0, cost: 0, msId: '' }],
            },
        };
    }

    if (action.type === types.INVEST_ASSET_DELETED) {
        const portfolio = state[action.portfolio];
        const { assets } = portfolio;
        return {
            ...state,
            isFetching: false,
            [action.portfolio]: {
                ...portfolio,
                assets: [
                    ...assets.slice(0, action.index),
                    ...assets.slice(action.index + 1),
                ],
            },
        };
    }

    if (action.type === types.INVEST_PORTFOLIO_DATA_UPDATED) {
        const portfolio = state[action.portfolio];
        return {
            ...state,
            isFetching: false,
            [action.portfolio]: {
                ...portfolio,
                [action.name]: action.value,
            },
        };
    }

    if (action.type === types.INVEST_PORTFOLIO_BULK_DATA_UPDATED) {
        let updatedState = { ...state };
        for (let index = 0; index < action.portfolioFieldUpdates.length; index += 1) {
            const portfolioFieldUpdate = action.portfolioFieldUpdates[index];

            const portfolio = updatedState[portfolioFieldUpdate.name];
            updatedState = {
                ...updatedState,
                [portfolioFieldUpdate.name]: {
                    ...portfolio,
                    [portfolioFieldUpdate.fieldName]: portfolioFieldUpdate.fieldValue,
                },
            };
        }

        return {
            ...updatedState,
            isFetching: false,
        };
    }

    if (action.type === types.INVEST_CHECK_SUCCESS) {
        const output = action.results.portfolioCostResults.map(r => arrayToObject(r.costTable, 'id', 'value'));
        const portfolioSum = getAssetsSum(state.currentPortfolio.assets);
        const costComparison = getCostComparison(output, parseFloat(state.oneTimeManagementCost), portfolioSum);
        return {
            ...state,
            result: {
                ...state.result,
                date: action.results.calculationDate,
                input: {
                    currentPortfolio: state.currentPortfolio,
                    targetPortfolio: state.targetPortfolio,
                    oneTimeManagementCost: state.oneTimeManagementCost,
                    calculationSettings: state.calculationSettings ?? null,
                },
                portfolioSum,
                costComparison,
                currentAllocation: action.results.currentAllocation,
                targetAllocation: action.results.targetAllocation,
                currentPortfolioPerformance: action.results.currentPortfolioPerformance,
                targetPortfolioPerformance: action.results.targetPortfolioPerformance,
                currentPortfolioVolatility: action.results.currentPortfolioVolatility,
                targetPortfolioVolatility: action.results.targetPortfolioVolatility,
                currentPortfolioMifidCost: action.results.currentPortfolioMifidCost,
                targetPortfolioMifidCost: action.results.targetPortfolioMifidCost,
                currentPortfolioCountryExposure: action.results.currentPortfolioCountryExposure,
                targetPortfolioCountryExposure: action.results.targetPortfolioCountryExposure,
            },
            checkLoading: false,
        };
    }

    switch (action.type) {
        case types.USER_RESET_STATE:
            return { ...initialState.investData, isFetching: true };
        case types.INVEST_DATA_LOADED:
            return {
                ...initialState.investData,
                ...action.investData,
                isFetching: false,
            };
        case types.INVEST_DATA_LOAD_REQUEST:
            return {
                ...initialState.investData,
                isFetching: true,
            };
        case types.INVEST_DATA_UPDATED:
            return {
                ...state,
                [action.name]: action.value,
                isFetching: false,
            };
        case types.INVEST_DATA_BULK_UPDATED:
        {
            let updatedState = { ...state };
            for (let index = 0; index < action.investDataFieldUpdates.length; index += 1) {
                const investDataFieldUpdate = action.investDataFieldUpdates[index];
                updatedState = {
                    ...updatedState,
                    [investDataFieldUpdate.fieldName]: investDataFieldUpdate.fieldValue,
                };
            }
            return {
                ...updatedState,
                isFetching: false,
            };
        }
        case types.REPORT_REQUESTED:
            return { ...state, reportLoading: true };
        case types.REPORT_SUCCESS:
            return { ...state, reportLoading: false };
        case types.REPORT_FAIL:
            return { ...state, reportLoading: false };
        case types.INVEST_CHECK_REQUESTED:
            return { ...state, result: false, error: false, checkLoading: true };
        case types.INVEST_CHECK_FAIL:
            return {
                ...state,
                result: false,
                error: action.error.toString(),
                checkLoading: false,
            };
        default:
            return state;
    }
}
