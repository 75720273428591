import { omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { db, storage } from './database.js';
// import { getUsersFiles } from '../apiRepository/teal';


export function partnerRef(partnerId) {
    return db.doc(`partners/${partnerId}`);
}

export function customersRef(partnerId) {
    return partnerRef(partnerId).collection('customers');
}

export function customerRef(partnerId, customerId) {
    return customersRef(partnerId).doc(customerId);
}

export function customerFilesRef(partnerId, customerId) {
    return customerRef(partnerId, customerId).collection('files');
}

export function customerFilesStorageRef(partnerId, customerId) {
    return storage.ref(`partners/${partnerId}/customers/${customerId}/files`);
}

export function customerFileStorageRef(partnerId, customerId, fileId) {
    return customerFilesStorageRef(partnerId, customerId).child(fileId);
}

export async function getCustomer(partnerId, customerId) {
    const customerSnapshot = await customerRef(partnerId, customerId).get();
    if (!customerSnapshot.exists) {
        throw new Error(`Customer with partnerId ${partnerId} and customerId ${customerId} not found`);
    }

    const customer = customerSnapshot.data();

    const queryResult = await customerFilesRef(partnerId, customerId).get();

    const files = { };
    const firestoreFilesList = queryResult.docs.map(doc => doc.data());

    firestoreFilesList.forEach(file => {
        files[file.fileId] = file;
    });

    const storageFiles = await customerFilesStorageRef(partnerId, customerId).listAll();
    const storageFilesMetadata = await Promise.all(storageFiles.items.map(ref => ref.getMetadata()));

    await Promise.all(storageFilesMetadata.map(async (metadata, i) => {
        const fileId = metadata.name;
        if (!files[fileId]) return;

        files[fileId].url = await storageFiles.items[i].getDownloadURL();
        files[fileId].deletable = true;
    }));

    const filesArray = Object.values(files)
        .filter(file => file.url)
        .sort((a,b) => a.createdAt < b.createdAt ? 1 : -1);

    const penlyFiles = [];

    // disables penly files for now - will be re-enabled soon

    // const newayUserId = customer.neway?.newayUserId;
    // if (newayUserId) {
    //     try {
    //         newayFiles = await getUsersFiles(newayUserId);
    //     } catch (error) {
    //         if (error.response.status === 400 && error.response.data.code === 'NoValidSharings') {
    //             // ignore this, we just don't fetch the files
    //         } else {
    //             throw error;
    //         }
    //     }
    // }
    // newayFiles = newayFiles.map(file => ({ ...file, source: 'Neway' }));

    return { ...customer, partnerId, customerId, files: [...Object.values(filesArray), ...penlyFiles] };
}

export async function getCustomerBaseData(partnerId, customerId) {
    const customerSnapshot = await customerRef(partnerId, customerId).get();
    if (!customerSnapshot.exists) {
        throw new Error(`Customer with partnerId ${partnerId} and customerId ${customerId} not found`);
    }

    const customer = customerSnapshot.data();

    return { ...customer, partnerId, customerId };
}

export async function getAllCustomers(partnerId) {
    const customers = await customersRef(partnerId).orderBy('createdAt','desc').get();
    return customers.docs.map(a => ({ ...a.data(), partnerId, customerId: a.id }));
}

export function updateCustomer(customer) {
    return customerRef(customer.partnerId, customer.customerId).set(omit(customer, ['partnerId', 'customerId', 'files']));
}

export async function getFile(partnerId, customerId, fileId) {
    let fileData;
    let url;
    try {
        const file = await db.collection(`partners/${partnerId}/customers/${customerId}/files`).doc(fileId).get();
        fileData = file.data();
        url = await customerFileStorageRef(partnerId, customerId, fileId).getDownloadURL();
    } catch (error) {
        return null;
    }

    return { ...fileData, url };
}

export async function uploadFile(customer, file, metadata = { }, spouse = false) {
    const { partnerId, customerId } = customer;
    const fileId = uuidv4();
    const fileRef = customerFileStorageRef(partnerId, customerId, fileId);
    await fileRef.put(file);

    const fileData = {
        fileId,
        fileName: file.name,
        source: 'Upload',
        createdAt: (new Date()).toISOString(),
        fileType: file.fileType || 'unknown',
        metadata,
        spouse,
    };

    await customerFilesRef(partnerId, customerId).doc(fileId).set(fileData);

    return fileData;
}

export async function uploadFileOnboardingLink(customerId, partnerId, file, metadata = { }, spouse = false) {
    const fileId = uuidv4();
    const fileRef = customerFileStorageRef(partnerId, customerId, fileId);
    await fileRef.put(file);

    const fileData = {
        fileId,
        fileName: file.name,
        source: 'Upload',
        createdAt: (new Date()).toISOString(),
        fileType: file.fileType || 'unknown',
        metadata,
        spouse,
    };

    await customerFilesRef(partnerId, customerId).doc(fileId).set(fileData);

    return fileData;
}

export async function uploadPensionFileOnboardingLink(customerId, partnerId, file, fileName, metadata = { }, spouse = false) {
    const fileId = uuidv4();
    const fileRef = customerFileStorageRef(partnerId, customerId, fileId);
    await fileRef.put(file);

    const fileData = {
        fileId,
        fileName,
        source: 'Upload',
        createdAt: (new Date()).toISOString(),
        fileType: 'PensionsInfoFile',
        metadata,
        spouse,
    };

    await customerFilesRef(partnerId, customerId).doc(fileId).set(fileData);

    return fileData;
}

export async function getDownloadFileUrl(customer, fileId) {
    const { partnerId, customerId } = customer;
    const fileRef = customerFileStorageRef(partnerId, customerId, fileId);
    return fileRef.getDownloadURL();
}

export async function setFileMetaData(customer, fileId, metadata) {
    const { partnerId, customerId } = customer;
    const fileRef = customerFilesRef(partnerId, customerId).doc(fileId);
    await fileRef.set({ metadata }, { merge: true });
}

export async function deleteFile(customer, fileId) {
    const { partnerId, customerId } = customer;
    const fileRef = customerFileStorageRef(partnerId, customerId, fileId);
    await fileRef.delete();
    await customerFilesRef(partnerId, customerId).doc(fileId).delete();
}

export async function deleteFileOnboardingLink(customerId, partnerId, fileId) {
    const fileRef = customerFileStorageRef(partnerId, customerId, fileId);
    await fileRef.delete();
    await customerFilesRef(partnerId, customerId).doc(fileId).delete();
}
