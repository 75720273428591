import React from 'react';

import PageTemplate from './Pages/PageTemplate/PageTemplate.js';

class BlankReport extends React.Component {
    render() {
        let osName = 'Unknown OS';
        if (navigator.appVersion.indexOf('Win') !== -1) osName = 'Windows';
        if (navigator.appVersion.indexOf('Mac') !== -1) osName = 'MacOS';
        if (navigator.appVersion.indexOf('X11') !== -1) osName = 'UNIX';
        if (navigator.appVersion.indexOf('Linux') !== -1) osName = 'Linux';

        return (
            <div>
                <PageTemplate { ...this.props }>
                    <h1>Internal Stats</h1>
                    <p>Operating System: { osName }</p>
                </PageTemplate>
            </div>
        );
    }
}

BlankReport.propTypes = {

};

export default BlankReport;
