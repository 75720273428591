import * as types from './actionTypes.js';
import { updateTheme as updateThemeInFirestore } from '../databaseRepository/siteManager.js';
import initialState from '../reducers/initialState.js';
import { additionalColors, objectDifference } from '../utils/helperFunctions.js';

export function updateTheme(theme) {
    return (dispatch) => {
        dispatch({ type: types.SITE_CHANGED, payload: { theme } });
    };
}

export function saveTheme() {
    return (dispatch, getState) => {
        const { site, auth } = getState();

        // make sure to only save editable and non-default colors
        const initialTheme = { ...initialState.site.theme, colors: additionalColors(initialState.site.theme.colors) };
        const customTheme = objectDifference(site.theme, initialTheme);

        return updateThemeInFirestore(auth.user.partnerId, customTheme);
    };
}
