import { eventChannel } from 'redux-saga';
import { all, take, put, select, fork, cancelled, cancel } from 'redux-saga/effects';
import { mergeWith } from 'lodash';
import { db } from '../databaseRepository/database.js';
import * as types from '../actions/actionTypes.js';

function* onUpdatedConfig() {
    while(yield take(types.AUTH_DATA_LOADED)) {
        const snapshotListenerTask = yield fork(snapshotListener);
        yield take(types.AUTH_SIGNOUT);
        yield cancel(snapshotListenerTask);
    }
}
function* snapshotListener() {
    const updateChannel = eventChannel(emit => {
        const unsubscribe = db.doc('frontendConfig/singleton').onSnapshot(emit);
        return () => {
            unsubscribe();
        };
    });;

    try{
        while (true) {
            const globalConfig = yield take(updateChannel);

            // reload if maintenance has ended
            const previousConfig = yield select(state => state.config);
            if (!globalConfig.maintenance && previousConfig.maintenance) return window.location.reload(true);

            // combine global config and partner config to one config
            const partnerConfig = yield select(state => state.partnerData?.config || { });
            const config = mergeConfigs(globalConfig.data(), partnerConfig);

            // store generated config to redux
            yield put({ type: types.CONFIG_CHANGED, payload: { config } });
        }
    } finally {
        if (yield cancelled()) updateChannel.close();
    }
}

function* onUpdatedPartnerData() {
    while (true) {
        const action = yield take(types.PARTNER_DATA_UPDATED);
        const globalConfigDocument = yield db.collection('frontendConfig').doc('singleton').get();
        const globalConfig = globalConfigDocument.data();
        const partnerConfig = action.partnerData?.config || { };
        const config = mergeConfigs(globalConfig, partnerConfig);
        yield put({ type: types.CONFIG_CHANGED, payload: { config } });
    }
}

export default function* configSaga() {
    yield all([
        onUpdatedConfig(),
        onUpdatedPartnerData(),
    ]);
}


function mergeConfigs(baseConfig, extraConfig) {
    return mergeWith(baseConfig, extraConfig, arrayMerge);
}

function arrayMerge(objValue, srcValue) {
    if (Array.isArray(objValue)) return objValue.concat(srcValue);
}
