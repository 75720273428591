import React from "react";
import PropTypes from "prop-types";
import { useTheme, CssBaseline } from "@mui/material";

import { useWindowSize } from "../../../hooks/index.js";
import NavBar from "../NavBar/index.js";
import SideBar from "../SideBar/index.js";
import ContentHeader from "../ContentHeader/index.js";
import ErrorBoundary from "../ErrorBoundary/index.js";

const Layout = (props) => {
  const { sidebarBottomContent, sidebarTopContent, logoPath } = props;
  const theme = useTheme();
  const { width } = useWindowSize();
  const isMobile = width < theme.breakpoints.values.md;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const maxWidth = 1110;

  return (
    <ErrorBoundary
      errorCallback={props.errorCallback}
      imgPath={props.errorBoundaryImgPath}
    >
      <div style={{ display: "flex" }}>
        <CssBaseline />
        <NavBar
          isMobile={isMobile}
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
          logoPath={logoPath}
        />
        <SideBar
          isMobile={isMobile}
          mobileOpen={mobileOpen}
          bottomContent={sidebarBottomContent}
          topContent={sidebarTopContent}
          setMobileOpen={setMobileOpen}
        />

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            padding: !isMobile && 30,
            marginTop: 60,
            height: "calc(100vh - 60px)",
            marginLeft: !isMobile && 184,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              maxWidth: `${maxWidth}px`,
              flexDirection: "column",
            }}
          >
            <ContentHeader isMobile={isMobile} maxWidth={maxWidth} />

            <main
              style={{
                width: "100%",
                maxWidth: `${maxWidth}px`,
                paddingBottom: !isMobile && 30,
              }}
            >
              {props.children}
            </main>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

Layout.propTypes = {
  sidebarBottomContent: PropTypes.node,
  sidebarTopContent: PropTypes.node,
  logoPath: PropTypes.string,
  errorCallback: PropTypes.func,
  errorBoundaryImgPath: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Layout;
