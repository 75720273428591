import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Drawer, Divider, IconButton } from "@mui/material";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";

const SideBar = (props) => {
  const { mobileOpen, isMobile, setMobileOpen, bottomContent, topContent } =
    props;
  const mobileDrawer = { variant: "persistent", open: mobileOpen };
  const desktopDrawer = { variant: "persistent", open: true };
  const drawerOptions = isMobile ? mobileDrawer : desktopDrawer;

  return (
    <Drawer {...drawerOptions}>
      <div style={{ padding: "8px", marginTop: "60px" }}>
        {isMobile && (
          <Fragment>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <IconButton onClick={() => setMobileOpen(false)} size="large">
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider style={{ marginBottom: 8, marginTop: 8 }} />
          </Fragment>
        )}
        {topContent}

        <div
          style={{
            position: "absolute",
            bottom: 16,
            width: "calc(100% - 16px)",
          }}
        >
          {bottomContent}
        </div>
      </div>
    </Drawer>
  );
};

SideBar.propTypes = {
  mobileOpen: PropTypes.bool,
  isMobile: PropTypes.bool,
  setMobileOpen: PropTypes.func,
  bottomContent: PropTypes.node,
  topContent: PropTypes.node,
};

export default SideBar;
