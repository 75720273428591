import axios from 'axios';
import { config } from '../config/index.js';
import { encode } from '../utils/base64.js';

function showFile(blob, filename) {
    if (blob.size === 0) {
        alert('Kunne ikke hente fil'); // eslint-disable-line no-alert
        return;
    }
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([blob], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = filename;
    link.click();
    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
    }, 100);
}

export async function downloadReport(variation, investData, customer, partnerData, site, features) {
    const state = {
        reportType: 'investCheck',
        variation,
        investData,
        customer: { name: customer.name },
        partnerData,
        site,
        config: { features },
    };

    const urlParts = window.location.href.split('/');

    const delay = 50;
    if(config.env === 'local-dev') console.log(encode(JSON.stringify(state)));
    const script = `window.setReportState('${encode(JSON.stringify(state))}')`;

    await axios({
        method: 'post',
        url: config.pdfReportServiceUrl,
        data: {
            href: `${urlParts[0]}//${ config.env !== 'local-dev' ? urlParts[2] : config.pdfReportServiceCallbackUrl}/report`,
            script,
            delay,
            waitForFunction: 'window.isReadyForPDF',
        },
        timeout: 5 * 60 * 1000,
        responseType: 'blob',
    })
        .then(response => showFile(response.data, `InvestTjek_${customer.name.replace(' ', '_')}`));
}

export async function downloadMifidReport(mifidData, customer, partnerData, site, footer, header) {
    const state = {
        reportType: 'mifid',
        mifidData,
        partnerData,
        site,
        customer: { name: customer.name },
    };
    const urlParts = window.location.href.split('/');

    const delay = 50;

    const script = `window.setReportState('${encode(JSON.stringify(state))}')`;

    await axios({
        method: 'post',
        url: config.pdfReportServiceUrl,
        data: {
            href: `${urlParts[0]}//${ config.env !== 'local-dev' ? urlParts[2] : config.pdfReportServiceCallbackUrl}/report`,
            script,
            delay,
            pdf: {
                landscape: false,
                displayHeaderFooter: true,
                footerTemplate: footer,
                headerTemplate: header,
                margin: {
                    top: '100px',
                    bottom: '50px',
                },
            },
        },
        timeout: 5 * 60 * 1000,
        responseType: 'blob',
    })
        .then(response => showFile(response.data, `MiFID_${customer.name.replace(' ', '_')}`));
}
