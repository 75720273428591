import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material";

const StyledDiv = styled("div")(({ theme }) => ({
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "15px",
  lineHeight: "21px",
  color: theme.palette.secondary.main,
  cursor: "pointer",
  "&:hover": {
    opacity: 0.8,
  },
}));

const LinkButton = (props) => (
  <StyledDiv style={props.style} onClick={props.onClick}>
    {props.children}
  </StyledDiv>
);

LinkButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  style: PropTypes.object,
};

export default LinkButton;
