import { omit } from 'lodash';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { getProcessesDbRef } from './processDataManager.js';

function investRef(partnerId, customerId, processId) {
    return getProcessesDbRef(partnerId, customerId)
        .doc(processId)
        .collection('data')
        .doc('investData');
}

export async function getInvestProcess(partnerId, customerId, processId) {
    const investProcess = await investRef(partnerId, customerId, processId).get();
    if (investProcess.exists) {return { ...investProcess.data(), partnerId, customerId, processId };}
    return { partnerId, customerId, processId };
}

function _updateInvestProcess(investProcess) {
    return investRef(
        investProcess.partnerId,
        investProcess.customerId,
        investProcess.processId,
    ).set(omit(investProcess, ['partnerId', 'customerId', 'processId']));
}

const updatedInvestProcessDebounced = AwesomeDebouncePromise(
    _updateInvestProcess,
    1000,
    {
        key: (investProcess) =>
      `${investProcess.customerId}${investProcess.processId}`,
    },
);

export function updateInvestProcess(investProcess) {
    return updatedInvestProcessDebounced(investProcess);
}
