import React from "react";
import PropTypes from "prop-types";

class DannebroginvestPageTemplate extends React.Component {
  render() {
    const { children, alternativeFooter = false } = this.props;
    return (
      <div
        className="page A4-landscape"
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <div style={{ height: "40px" }} />
        <div style={{ flexGrow: 1, margin: "10px 20px" }}>{children}</div>
        {!alternativeFooter ? (
          <div
            style={{ height: "40px", textAlign: "center", color: "#999999" }}
          >
            Dannebrog Invest | Jernbanegade 23, 4000 Roskilde | CVR: 39763303
          </div>
        ) : (
          <div style={{ height: "40px", textAlign: "center" }}>
            Dannebrog Invest | Jernbanegade 23, 4000 Roskilde | CVR: 39763303
          </div>
        )}
      </div>
    );
  }
}
DannebroginvestPageTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  alternativeFooter: PropTypes.bool,
};

export default DannebroginvestPageTemplate;
