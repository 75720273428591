import React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { PersonOutline as PersonOutlineIcon } from "@mui/icons-material";
import { Link as RouterLink, useLocation } from "react-router-dom";

const SideBarTopContent = () => {
  const { pathname } = useLocation();

  return (
    <div>
      <List disablePadding component="nav" aria-label="sidebar top menu">
        <ListItem
          button
          component={RouterLink}
          to="/kunder"
          selected={pathname === "/kunder"}
        >
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Kunder" />
        </ListItem>

        {/* PLOP_INJECT_PAGE */}
      </List>
    </div>
  );
};

SideBarTopContent.propTypes = {};

export default SideBarTopContent;
