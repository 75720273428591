import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import Tabs from './Tabs/index.js';

const NamedTabs = ({ tabs, hashIndex = 0 }) => {
    const location = useLocation();
    const history = useHistory();

    const label = decodeURIComponent(location.hash).split('#')[hashIndex + 1];
    const tab = tabs.find(t => t.label === label);

    const handleTabChange = useCallback((index, type = 'push') => {
        const newHash = [
            ...location.hash.split('#').slice(0, hashIndex + 1),
            tabs[index].label,
        ];
        if (newHash.length === 1) newHash.unshift('');
        history[type](`${newHash.join('#')}`);
    }, [tabs, history, hashIndex, location.hash]);

    // we set the current tab to the default name, if not already set
    useEffect(() => {
        const defaultTabIndex = tabs.findIndex(t => t.default);
        if (!label) handleTabChange(defaultTabIndex, 'replace');
    }, [label, tabs, handleTabChange]);

    if (!label) return null;

    return (
        <>
            <Tabs
                tabs={ tabs.sort((a, b) => a.order > b.order ? 1 : -1) }
                value={ Math.max(0, tabs.findIndex(t => t.label === label)) }
                setValue={ handleTabChange }
            />
            { tab && <tab.component { ...tab.props } /> }
        </>
    );
};

NamedTabs.propTypes = {
    tabs: PropTypes.array.isRequired,
    hashIndex: PropTypes.number,
};

export default NamedTabs;
