import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const PensionFlereOplysningerTable = ({ pensionFlereOplysninger }) => {
    return (
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Leverandør, aftalenr. og pensionstype</TableCell>
                    <TableCell align="left">Udbetaling</TableCell>
                    <TableCell align="left">Indbetaling</TableCell>
                    <TableCell align="left">Opsparing</TableCell>
                    <TableCell align="left">Øvrigt</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { pensionFlereOplysninger.map((row) => (
                    <TableRow key={ row.aftalenr + row.leverandoer + row.pensionsType }>
                        <TableCell component="th" scope="row">
                            <div>
                                <strong>{ row.leverandoer }</strong>
                            </div>
                            <div>{ row.aftaleNr }</div>
                            <div>{ row.pensionsType }</div>
                        </TableCell>
                        <TableCell style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>
                            { row.udbetalingText }
                        </TableCell>
                        <TableCell style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>
                            { row.indbetalingText }
                        </TableCell>
                        <TableCell style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>
                            { row.opsparingText }
                        </TableCell>
                        <TableCell style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>
                            { row.oevrigt }
                        </TableCell>
                    </TableRow>
                )) }
            </TableBody>
        </Table>
    );
};
PensionFlereOplysningerTable.propTypes = {
    pensionFlereOplysninger: PropTypes.array.isRequired,
};
export default PensionFlereOplysningerTable;
