import initialState from './initialState.js';
import * as types from '../actions/actionTypes.js';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function processesDataReducer(state = initialState.processesData, action) {
    switch (action.type) {
        case types.PROCESSES_DATA_UPDATE_REQUEST: {
            return { ...state, isFetching: true };
        }
        case types.PROCESSES_DATA_UPDATED:
            return { ...state, ...action.processesData, isFetching: false };
        case types.PROCESS_DATA_FIELD_UPDATED: {
            const { processes } = state;
            const index = getIndex(processes, action.processId);
            const process = processes[index];
            const updatedProcess = {
                ...process,
                [action.fieldName]: action.fieldValue,
            };

            return {
                ...state,
                processes: [
                    ...processes.slice(0, index),
                    updatedProcess,
                    ...processes.slice(index + 1),
                ],
            };
        }
        default:
            return state;
    }
}

function getIndex(processes, processId) {
    return processes.findIndex(process => process.processId === processId);
};
